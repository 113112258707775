import './common.css';
import { styled } from '@mui/material/styles';
import { InputAdornment, TextField } from '@mui/material';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00235F',
    },
    '& label': {
        color: '#000',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00235F',
    }
});

function CustomMuiInputNormal({
    lable, val, setVal, editValData, type, autofocus, multiline, maxRows, minRows, mt, name, readOnly, startAdornment, 
    variant, size
}) {

    const onChangeValUsingName = (e) => setVal({ ...editValData, [e.target.name]: e.target.value });
    return (
        <CssTextField
            className='custom_mui_noSpinner'
            label={lable}
            value={val}
            name={name ? name : ''}
            onChange={(e) => name ? onChangeValUsingName(e) : setVal(e.target.value)}
            variant={variant || "standard"}
            type={type ? type : 'text'}
            autoFocus={autofocus ? true : false}
            fullWidth
            InputProps={{
                readOnly: readOnly ? true : false,
                startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : ''
            }}
            size={size || 'medium'}
            // TextArea
            multiline={multiline}
            maxRows={multiline ? maxRows : 1}
            minRows={multiline ? minRows : 1}
            style={{ marginTop: mt ? mt : 10 }}
        />
    )
}

export default CustomMuiInputNormal