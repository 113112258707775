import './CalculateTripProfilt.css';
import '../PayYourDriver/PayYourDriver.css';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router";
import { IonIcon, IonRange } from '@ionic/react';
import axios from 'axios';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
// Components
import StandardAndroidBtn from '../../components/StandardAndroidBtn/StandardAndroidBtn';
import CustomIonicWrapper from '../../components/CustomIonicWrapper/CustomIonicWrapper';
import CustomMuiInputNormal from '../../components/CustomMuiInput/CustomMuiInputNormal';
// ICONs & SVGs
import { arrowForwardCircleOutline } from 'ionicons/icons';

function CalculateTripProfilt() {
    const location = useLocation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [miles, setMiles] = useState('');
    const [rate, setRate] = useState('');
    const [calloading, setCalloading] = useState(false);

    const [newRate, setNewRate] = useState('');
    // Range
    const [range, setRange] = useState(0);
    const [rangeInput, setRangeInput] = useState(0);
    const [rangeLoading, setRangeLoading] = useState(false);

    useEffect(() => {
        if (location.pathname == '/calculate-profit') {
            setMiles('');
            setRate('');
            setNewRate('');
            setRange(0);
            setRangeInput(0);
        }
    }, [location.pathname])

    const onCalculate = async () => {
        if (!rate || !miles) return enqueueSnackbar('Please Add all the Fields', { variant: 'error' });
        setCalloading(true);

        const body = {
            ...location.state?.body,
            trip_distance: parseFloat(miles),
            trip_cost: parseFloat(rate)
        }

        await axios.post(`${process.env.REACT_APP_BASEURL}/trip/getpro`, body)
            .then(res => {
                if (res.data.success) {
                    setRange(parseFloat(res.data.profit));
                    setRangeInput(parseFloat(res.data.profit));
                    setNewRate('')
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setCalloading(false);
            })
            .catch(err => {
                console.log(err);
                setCalloading(false);
            })
    }

    const onRangeChange = async (e) => {
        if (!miles) return enqueueSnackbar('Please Enter Miles', { variant: 'error' });

        setRange(e.target.value)
        setRangeLoading(true);

        const body = {
            ...location.state.body,
            trip_distance: parseFloat(miles),
            profit_exp: e.target.value
        }

        await axios.post(`${process.env.REACT_APP_BASEURL}/trip/gettripcost`, body)
            .then(res => {
                if (res.data.success) {
                    setNewRate(res.data.trip_cost);
                    setRangeInput(e.target.value)
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setRangeLoading(false);
            })
            .catch(err => {
                console.log(err);
                setRangeLoading(false);
            })
    }

    const handleRangeInput = async () => {
        if (!miles) return enqueueSnackbar('Please Enter Miles', { variant: 'error' });

        if (parseFloat(rangeInput) > 10000) return enqueueSnackbar("Range can't be more than $10,000", { variant: 'error' });
        if (parseFloat(rangeInput) < 0) return enqueueSnackbar("Range can't be less than $0", { variant: 'error' });

        const body = {
            ...location.state.body,
            trip_distance: parseFloat(miles),
            profit_exp: parseFloat(rangeInput)
        }

        await axios.post(`${process.env.REACT_APP_BASEURL}/trip/gettripcost`, body)
            .then(res => {
                if (res.data.success) {
                    setNewRate(res.data.trip_cost);
                    setRange(parseFloat(rangeInput))
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setRangeLoading(false);
            })
            .catch(err => {
                console.log(err);
                setRangeLoading(false);
            })
    }

    return (
        <CustomIonicWrapper title='Calculate Profit' onBackClick={() => history.push('/home')}>
            <div className='payDriver_cont' >
                <section className="payDriver_section">
                    <div className="calcProfit_data_cont">
                        <div className="calcProfit_calculate">
                            <CustomMuiInputNormal val={miles} setVal={setMiles} type='number' variant='outlined'
                                lable='Enter Miles for this trip' startAdornment='Miles'
                            />

                            <CustomMuiInputNormal val={rate} setVal={setRate} type='number' variant='outlined' mt={20}
                                lable='Enter Rate for this trip' startAdornment='$'
                            />

                            <StandardAndroidBtn label='Calculate' onClick={onCalculate} loading={calloading} style={{ marginBottom: '10px' }} />

                            <h2>Profit for the Trip</h2>
                            <h4>${parseFloat(range).toFixed(2)}</h4>

                            <div className='calcProfit_rangeCont'>
                                {/* <p>Profit you need for this Trip</p> */}
                                <p>Select Profit Needed: ${range}</p>
                                <div className='calcProfit_rangeCont_rangeInput' >
                                    <CustomMuiInputNormal val={parseFloat(rangeInput) < 0 ? 0 : parseFloat(rangeInput)}
                                        setVal={setRangeInput} type='number' variant='outlined' mt={20}
                                        lable='Select Profit Needed' size='small' startAdornment='$'
                                    />

                                    <IconButton sx={{ color: '#00235F', marginLeft: 1, marginBottom: '-5px' }} onClick={handleRangeInput} >
                                        <IonIcon icon={arrowForwardCircleOutline} style={{ fontSize: '35px' }} />
                                    </IconButton>
                                </div>

                                <div className='calcProfit_rangeCont_range' >
                                    <IonRange value={parseFloat(range)} pin={true} mode='ios'
                                        onIonKnobMoveEnd={onRangeChange} max={10000} disabled={rangeLoading}
                                    />
                                </div>
                            </div>

                            {!!newRate && (
                                <>
                                    <h5>New Rate For The Trip</h5>
                                    <h6>Rate: ${newRate}</h6>
                                </>
                            )}
                        </div>

                        <div className='payDriver_btns_cont' >
                            <StandardAndroidBtn label='Back' onClick={() => history.push('/pay-driver')} />

                            {/* <StandardAndroidBtn label='Calculate' onClick={onCalculate} loading={calloading} /> */}
                        </div>
                    </div>
                </section>
            </div>
        </CustomIonicWrapper>
    )
}

export default CalculateTripProfilt