import { IonAlert } from '@ionic/react';
import './CustomAlert.css';

function CustomAlert({ open, onDismiss, header, message }) {
    return (
        <IonAlert
            mode='ios'
            isOpen={open}
            onDidDismiss={onDismiss}
            header={header}
            message={message}
            backdropDismiss={false}
            cssClass='customAlert_container'
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Confirm',
                    role: 'confirm',
                    cssClass: 'customAlert_confirm'
                },
            ]}
        // subHeader="Important message"
        />
    )
}

export default CustomAlert