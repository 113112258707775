import { FETCH_ALL_TRIPS, ADD_TRIP_INFO_1, ADD_TRIP_INFO_2, ADD_TRIP_INFO_3, RESET_TRIP_INFO } from "../type";

const initialState = {
    tripData: {
        truck_id: '',
        user_id: '',
        date_of_trip: '',
        profit: '',
        trip_number: '',
        rate: '',
        from_city: '',
        from_state: '',
        to_city: '',
        to_state: '',
        total_loaded_miles: '',
        comments: '',
        bill_of_lading: '',
        rate_confirmation: '',
        fData: ''
    },
    tripInfo1_done: false,
    tripInfo2_done: false,
    tripInfo3_done: false,
    getAllTrips: []
}

export const tripDataReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case ADD_TRIP_INFO_1:
            return state = {
                ...state,
                tripData: {
                    ...state.tripData,
                    ...actions.tripData_1
                },
                tripInfo1_done: actions.tripInfo1_done
            }

        case ADD_TRIP_INFO_2:
            return state = {
                ...state,
                tripData: {
                    ...state.tripData,
                    ...actions.tripData_2
                },
                tripInfo2_done: actions.tripInfo2_done
            }

        case ADD_TRIP_INFO_3:
            return state = {
                ...state,
                tripData: {
                    ...state.tripData,
                    ...actions.tripData_3
                },
                tripInfo3_done: actions.tripInfo3_done
            }

        case RESET_TRIP_INFO:
            return state = {
                ...state,
                tripData: {
                    truck_id: '',
                    user_id: '',
                    date_of_trip: '',
                    profit: '',
                    trip_number: '',
                    rate: '',
                    from_city: '',
                    from_state: '',
                    to_city: '',
                    to_state: '',
                    total_loaded_miles: '',
                    comments: '',
                    bill_of_lading: '',
                    rate_confirmation: '',
                    fData: ''
                },
                tripInfo1_done: false,
                tripInfo2_done: false,
                tripInfo3_done: false
            }

        case FETCH_ALL_TRIPS:
            return state = {
                ...state,
                getAllTrips: actions.payload
            }

        default:
            return state;
    }
}