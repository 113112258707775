import {
    ADD_USER, UPDATE_USER_LOGIN, LOGOUT_USER, ADD_COMPANY_DATA, SUBSCRIPTION_COMPANY_DATA, UPDATE_PAYPAL_SUB_STATUS,
    UPDATE_PAYPAL_SUBSCRIPTION_DATA, FISRT_USER_LOGIN_MODAL_UPDATE, UPDATE_IAP_SUB_STATUS
} from "../type";

const initialState = {
    userData: {
        first_name: '',
        middle_name: '',
        last_name: '',
        mobile_no: '',
        email: ''
    },
    companyData: {
        company_name: '',
        street_1: '',
        street_2: '',
        city: '',
        state: '',
        zip: '',
        dot_no: '',
        mc_no: ''
    },
    subscriptionData: {},
    paypalSubscriptionStatus: undefined,
    paypalSubscriptionData: null,
    userLoggedIn: undefined,
    showFirstLoginModal: false,
    // In App Purchases
    IAPsubscriptionStatus: undefined,
}

export const userReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case ADD_USER:
            return state = {
                ...state,
                userData: actions.payload
            }

        case ADD_COMPANY_DATA:
            return state = {
                ...state,
                companyData: actions.payload
            }

        case SUBSCRIPTION_COMPANY_DATA:
            return state = {
                ...state,
                subscriptionData: actions.payload
            }

        case UPDATE_USER_LOGIN:
            return state = {
                ...state,
                userLoggedIn: actions.payload
            }

        case UPDATE_PAYPAL_SUB_STATUS:
            return state = {
                ...state,
                paypalSubscriptionStatus: actions.payload
            }

        case UPDATE_PAYPAL_SUBSCRIPTION_DATA:
            return state = {
                ...state,
                paypalSubscriptionData: actions.payload
            }

        case FISRT_USER_LOGIN_MODAL_UPDATE:
            return state = {
                ...state,
                showFirstLoginModal: actions.payload
            }

        case UPDATE_IAP_SUB_STATUS:
            return state = {
                ...state,
                IAPsubscriptionStatus: actions.payload
            }

        case LOGOUT_USER:
            return state = {
                ...state,
                userData: {
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    mobile_no: '',
                    email: ''
                },
                companyData: {
                    company_name: '',
                    street_1: '',
                    street_2: '',
                    city: '',
                    state: '',
                    zip: '',
                    dot_no: '',
                    mc_no: ''
                },
                subscriptionData: {},
                paypalSubscriptionStatus: undefined,
                paypalSubscriptionData: null,
                userLoggedIn: actions.payload,
                showFirstLoginModal: false
            }
        default:
            return state;
    }
}