import './MyAccount.css'
import { useEffect, useState } from 'react';
import { IonAvatar, IonButton, IonIcon } from '@ionic/react'
import { useHistory, useLocation } from "react-router";
import axios from 'axios';
import { Alert, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { useSnackbar } from 'notistack';
// Storage
import { checkUserData } from '../../redux/actions/exportFunctions';
import { Preferences } from '@capacitor/preferences';
// Redux
import { ADD_COMPANY_DATA, LOGOUT_USER } from '../../redux/type';
import { useDispatch } from 'react-redux';
// Components
import StandardAndroidBtn from '../../components/StandardAndroidBtn/StandardAndroidBtn';
import CustomIonicWrapper from '../../components/CustomIonicWrapper/CustomIonicWrapper';
import CustomMuiInput from '../../components/CustomMuiInput/CustomMuiInput';
import EditModal from '../../components/EditModal/EditModal';
import CustomMuiInputNormal from '../../components/CustomMuiInput/CustomMuiInputNormal';
// ICONs & SVGs
import { pencil } from 'ionicons/icons';
import account from '../../imgs/account.png';

function MyAccount() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);
    const [allUserData, setAllUserData] = useState({ first_name: '', middle_name: '', last_name: '', email: '', mobile_no: '' });
    // USER AVATAR
    const [userAvatarPreview, setUserAvatarPreview] = useState('');
    const [userAvatarLoading, setUserAvatarLoading] = useState(false);
    // COMPANY DETAILS
    const [companyData, setCompanyData] = useState({ company_name: '', street_1: '', street_2: '', city: '', state: '', zip: '', dot_no: '', mc_no: '' });
    const [companydisable, setCompanydisable] = useState(false);
    const [companyDetailsModal, setCompanyDetailsModal] = useState(false);
    const [companyDetailsloading, setCompanyDetailsLoading] = useState(false);
    // Delete Account
    const [deleteAccountModal, setDeleteAccountModal] = useState(false);
    const [deleteAccountModalLoading, setDeleteAccountModalLoading] = useState(false);

    useEffect(async () => {
        if (location.pathname == '/account') {
            if (await checkUserData()) {
                fetchUserData();
            }
        }
    }, [location.pathname])

    const fetchUserData = async () => {
        if (!await checkUserData()) return enqueueSnackbar('No User Found', { variant: 'error' });
        const { user_id } = await checkUserData();

        const body = { user_id }
        await axios.post(`${process.env.REACT_APP_BASEURL}/user/all-data`, body)
            .then(res => {
                if (res.data.success) {
                    // console.log(res.data.data?.[0]);
                    const {
                        first_name, middle_name, last_name, email, mobile_no, //USER DATA
                        company_name, address, city, state, zip, dot_no, mc_no
                    } = res.data.data?.[0];

                    setAllUserData({ first_name, middle_name, last_name, email, mobile_no })
                    setUserAvatarPreview(res.data.data?.[0]?.photo)

                    const cData = { company_name: company_name || '', street_1: JSON.parse(address)?.street_1 || '', street_2: JSON.parse(address)?.street_2 || '', city: city || '', state: state || '', zip: zip || '', dot_no: dot_no || '', mc_no: mc_no || '' }
                    setCompanyData(cData)
                    dispatch({ type: ADD_COMPANY_DATA, payload: cData })
                    setCompanydisable(!Object?.values(cData)?.includes(''));
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }

    const onLogoutClick = async () => {
        await Preferences.remove({ key: 'truckola_users' })
        dispatch({ type: LOGOUT_USER, payload: undefined });
        history.push('/login');
        setTimeout(() => {
            dispatch({ type: LOGOUT_USER, payload: false });
        }, 1000);
    }

    const onDeleteBtnClick = async () => {
        const { user_id } = await checkUserData();

        setDeleteAccountModalLoading(true);
        await axios.post(`${process.env.REACT_APP_BASEURL}/user/delete`, { user_id: user_id })
            .then(res => {
                if (res.data.success) {
                    setDeleteAccountModal(false);
                    enqueueSnackbar('Account Deleted Successfully !!!', { variant: 'success' })
                    onLogoutClick();
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setDeleteAccountModalLoading(false);
            })
            .catch(err => {
                console.log(err);
                setDeleteAccountModalLoading(false);
            })
    }

    const onAddUserIcon = async (e) => {
        const { user_id } = await checkUserData();

        const fData = new FormData();
        fData.append('user_id', user_id)
        fData.append('photo', e.target.files[0])

        setUserAvatarLoading(true);
        await axios.post(`${process.env.REACT_APP_BASEURL}/user/update-img`, fData)
            .then(res => {
                if (res.data.success) {
                    setUserAvatarPreview(res.data.data?.photo);
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setUserAvatarLoading(false);
            })
            .catch(err => {
                console.log(err);
                setUserAvatarLoading(false);
            })
    }

    const onCompanyDetailsSave = async (e) => {
        e.preventDefault();
        const cData = { ...companyData }
        delete cData.street_2;

        if (Object.values(cData)?.includes('')) return enqueueSnackbar('Please Fill All The Details !!!', { variant: 'error' });
        const { user_id } = await checkUserData();
        if (!user_id) return enqueueSnackbar('Something Went Wrong Please Try Again !!!', { variant: 'error' });

        setCompanyDetailsLoading(true);

        const address = { street_1: companyData.street_1, street_2: companyData.street_2 }
        const body = { ...companyData, user_id, address }
        await axios.post(`${process.env.REACT_APP_BASEURL}/company/add-company`, body)
            .then(res => {
                if (res.data.success) {
                    setCompanyDetailsModal(false);
                    setCompanydisable(true);
                    dispatch({ type: ADD_COMPANY_DATA, payload: companyData })
                    enqueueSnackbar('Company Details Saved Successfully', { variant: 'success' })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setCompanyDetailsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setCompanyDetailsLoading(false);
            })
    }

    const onManageSubscriptionClick = () => {
        if (!companydisable) {
            setCompanyDetailsModal(true);
            return enqueueSnackbar('Please Add Company Details !!!', { variant: 'error' })
        } else {
            history.push('/subscription')
        }
    }

    return (
        <CustomIonicWrapper title='Account' onBackClick={() => history.goBack()}>
            {loading ? <MyAccountSkeleton /> : (
                <div className="account_cont">
                    <div className="account_avatar_sec">
                        <div className="account_avatarCont" onClick={() => document.getElementById('avtImg').click()} >
                            <input hidden accept="image/*" type="file" onChange={onAddUserIcon} id='avtImg' />
                            <IonAvatar>
                                <img src={userAvatarPreview || account} />
                            </IonAvatar>
                            <IonIcon icon={pencil} />
                            {userAvatarLoading && <CircularProgress size={35} sx={{ color: '#fff' }} />}
                        </div>
                        <h3>{allUserData.first_name} {allUserData.last_name}</h3>
                    </div>

                    <div className="account_data_sec">
                        <div className='account_data_sec_inputCont' >
                            <div className="accountBtns_section">
                                <Button variant="outlined" sx={{ marginRight: '7.5px' }} onClick={() => setCompanyDetailsModal(true)} >
                                    Company Details
                                </Button>
                                <Button variant="outlined" sx={{ marginLeft: '7.5px' }} onClick={onManageSubscriptionClick} >
                                    Manage Subscriptions
                                </Button>
                            </div>

                            <CustomMuiInput lable='First Name' val={allUserData?.first_name} name='first_name' editValData={allUserData} setVal={setAllUserData} readOnly shrink disableUnderline />
                            <CustomMuiInput placeholder='Enter Middle Name' lable='Middle Name' val={allUserData?.middle_name} name='middle_name' editValData={allUserData} setVal={setAllUserData} mt={20} readOnly shrink disableUnderline />
                            <CustomMuiInput lable='Last Name' val={allUserData?.last_name} name='last_name' editValData={allUserData} setVal={setAllUserData} mt={20} readOnly shrink disableUnderline />
                            <CustomMuiInput lable='Email' val={allUserData?.email} name='email' editValData={allUserData} setVal={setAllUserData} mt={20} readOnly shrink disableUnderline />
                            <CustomMuiInput lable='Call Number' val={allUserData?.mobile_no} name='mobile_no' editValData={allUserData} setVal={setAllUserData} mt={20} readOnly shrink disableUnderline />

                            <div className="accountBtns_section_2" >
                                <Button className='account_delteBtn' onClick={() => setDeleteAccountModal(true)} >
                                    Delete Account
                                </Button>

                                <IonButton fill="clear" mode='ios' onClick={() => history.push('/t&c')} >
                                    T&C
                                </IonButton>
                            </div>
                        </div>

                        <StandardAndroidBtn label='Log Out' onClick={onLogoutClick} />
                    </div>

                    {/* Company Details */}
                    <EditModal open={companyDetailsModal} setOpen={setCompanyDetailsModal} onUpdate={onCompanyDetailsSave}
                        loading={companyDetailsloading} editModalText='Add Company Details' hideUpdateBtn={companydisable}
                    >
                        <CustomMuiInputNormal lable='Company Name' val={companyData?.company_name} name='company_name' editValData={companyData} setVal={setCompanyData} autofocus variant='outlined' readOnly={companydisable} />
                        <CustomMuiInputNormal lable='Address Street 1' val={companyData?.street_1} name='street_1' editValData={companyData} setVal={setCompanyData} variant='outlined' mt={20} readOnly={companydisable} />
                        <CustomMuiInputNormal lable='Address Street 2 (Optional)' val={companyData?.street_2} name='street_2' editValData={companyData} setVal={setCompanyData} variant='outlined' mt={20} readOnly={companydisable} />
                        <CustomMuiInputNormal lable='City' val={companyData?.city} name='city' editValData={companyData} setVal={setCompanyData} variant='outlined' mt={20} readOnly={companydisable} />
                        <FormControl fullWidth variant='outlined' sx={{ marginTop: '20px' }} >
                            <InputLabel sx={{ color: '#000' }} >State</InputLabel>
                            <Select value={companyData.state} label="State" onChange={e => setCompanyData({ ...companyData, state: e.target.value })} readOnly={companydisable} >
                                {statesArr?.map((t, i) => <MenuItem key={i} value={t}>{t}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <CustomMuiInputNormal lable='ZIP' val={companyData?.zip} name='zip' editValData={companyData} setVal={setCompanyData} variant='outlined' mt={20} type='number' readOnly={companydisable} />
                        <CustomMuiInputNormal lable='DOT Number' val={companyData?.dot_no} name='dot_no' editValData={companyData} setVal={setCompanyData} variant='outlined' mt={20} type='number' readOnly={companydisable} />
                        <CustomMuiInputNormal lable='MC Number' val={companyData?.mc_no} name='mc_no' editValData={companyData} setVal={setCompanyData} variant='outlined' mt={20} type='number' readOnly={companydisable} />

                        {!companydisable && <Alert severity="warning" className='account_editModal_alert'>Once Saved, Can't be Updated !!!</Alert>}
                    </EditModal>

                    {/* Delete Account */}
                    <EditModal open={deleteAccountModal} setOpen={setDeleteAccountModal} onUpdate={onDeleteBtnClick}
                        loading={deleteAccountModalLoading} editModalText='Delete Account' editModalColor='#d32f2f'
                    >
                        <div className="account_deleteAcc_modal_cont">
                            <h4>Are you sure, you want to delete your account?</h4>

                            <p>
                                All your Trucks, Calculations, Trips Information and Documents will be deleted and cannot be recovered.
                            </p>
                            <p>
                                Any Ongoing Subscription will be inactive and you will have to pay for new subscription if you want to reuse this same account.
                            </p>
                            <p>
                                You will not be allowed to reuse the email ID and mobile number for another account.
                            </p>
                        </div>
                    </EditModal>
                </div>
            )}
        </CustomIonicWrapper>
    )
}

export default MyAccount

const MyAccountSkeleton = () => (
    <div className="account_cont account_skeleton">
        <Skeleton variant="circular" width={100} height={100} animation='wave' />
        <Skeleton variant="rounded" width='75%' height={20} animation='wave' sx={{ marginTop: 1.5, maxWidth: 400 }} />
        <div className="account_data_sec">
            <div className='account_data_sec_inputCont' >
                <Skeleton variant="rounded" width='100%' height={30} animation='wave' />
                <Skeleton variant="rounded" width='100%' height={30} animation='wave' sx={{ marginTop: 1.5 }} />
                <Skeleton variant="rounded" width='100%' height={30} animation='wave' sx={{ marginTop: 1.5 }} />
                <Skeleton variant="rounded" width='100%' height={30} animation='wave' sx={{ marginTop: 1.5 }} />
                <Skeleton variant="rounded" width='100%' height={30} animation='wave' sx={{ marginTop: 1.5 }} />
            </div>

            <Skeleton variant="rounded" width='100%' height={40} animation='wave' sx={{ margin: 'auto', marginTop: 3, maxWidth: 300 }} />
        </div>
    </div>
)

const statesArr = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA",
    "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN",
    "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
]