import { configureStore } from '@reduxjs/toolkit'
// Reducers
import { userReducer } from './reducerd/userReducer'
import { truckDataReducer } from './reducerd/truckDataReducer'
import { tripDataReducer } from './reducerd/tripDataReducer'
import { notificationReducer } from './reducerd/notificationReducer'

export const store = configureStore({
    reducer: {
        user: userReducer,
        truck: truckDataReducer,
        trip: tripDataReducer,
        notification: notificationReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
})