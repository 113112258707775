import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useEffect } from 'react';
// Storage
import { checkUserData } from './redux/actions/exportFunctions';
// Pages
import Layout from './pages/Layout';

// REDUX
import { useDispatch } from 'react-redux';
import { ADD_USER, UPDATE_USER_LOGIN } from './redux/type';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
// import './theme/variables.css';

setupIonicReact({
  swipeBackEnabled: false,// also prevent swiping back on either platform
  hardwareBackButton: false// this is what you need (android only)
});

const App = () => {
  const dispatch = useDispatch();

  useEffect(async () => {
    if (await checkUserData()) {
      dispatch({ type: ADD_USER, payload: await checkUserData() })
      dispatch({ type: UPDATE_USER_LOGIN, payload: true })
    } else {
      dispatch({ type: UPDATE_USER_LOGIN, payload: false })
    }
  }, [])

  return (
    <IonApp>
      <IonReactRouter>
        <Layout />
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
