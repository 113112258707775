import './Services.css';
import { useHistory } from 'react-router'
import { useIonAlert } from '@ionic/react';
// Components
import CustomIonicWrapper from '../../components/CustomIonicWrapper/CustomIonicWrapper'
import HomePageCard from '../../components/HomePageCard/HomePageCard';
// IMGS & SVGS
import CustomerService from '../../imgs/homePage/customer-service.svg'

function Services() {
    const history = useHistory();
    const [presentAlert] = useIonAlert();

    const onCardClick = () => {
        presentAlert({
            header: 'Coming Soon !!!',
            cssClass: 'services_alert',
            buttons: [{ text: 'Ok', cssClass: 'alert-button-ok' }],
            backdropDismiss: false,
            keyboardClose: false,
            mode: 'ios'
        })
    }

    return (
        <CustomIonicWrapper title='Services' onBackClick={() => history.push('/home')}>
            <div className="services_cont">
                <section className="services_section">
                    <HomePageCard img={CustomerService} title='IFTA' onClick={onCardClick} />
                    <HomePageCard img={CustomerService} title='2290' onClick={onCardClick} />
                    <HomePageCard img={CustomerService} title='UCR' onClick={onCardClick} />
                    <HomePageCard img={CustomerService} title='IRP' onClick={onCardClick} />
                </section>
            </div>
        </CustomIonicWrapper>
    )
}

export default Services