import './InfoPageTempelate.css';
import StandardAndroidBtn from '../StandardAndroidBtn/StandardAndroidBtn';
// ICONs & SVGs
import { arrowForward } from 'ionicons/icons';

function InfoPageTempelate({ children, btn1Label, btn2Label, btn1Click, btn2Click, arrowClass, loading, pageTempelateHeader }) {
    return (
        <section className="infoTemp_data_section">
            <div className="infoTemp_data_cont">
                <div className={`infoTemp_data_arrow ${arrowClass ? arrowClass : ''}`} />

                {pageTempelateHeader && (<p>{pageTempelateHeader}</p>)}

                <div className="infoTemp_inputs_cont">
                    {children}
                </div>

                <div className='infoTemp_data_btns_cont' >
                    <StandardAndroidBtn label={btn1Label} onClick={btn1Click} />

                    <StandardAndroidBtn label={btn2Label ? btn2Label : 'Next'} onClick={btn2Click} iconRight={!btn2Label && arrowForward} loading={loading} />
                </div>
            </div>
            <div className="infoTemp_data_cont_backcard1"></div>
            <div className="infoTemp_data_cont_backcard2"></div>
        </section>
    )
}

export default InfoPageTempelate