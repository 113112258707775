import { useEffect, useState } from 'react';
import './AllTrucks.css';
import { useHistory, useLocation } from 'react-router';
import { checkUserId } from '../../../redux/actions/exportFunctions';
import { useSnackbar } from 'notistack';
import { isPlatform } from '@ionic/react';
import axios from 'axios';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_ALL_TRUCKS } from '../../../redux/type';
// Components
import StandardAndroidBtn from '../../../components/StandardAndroidBtn/StandardAndroidBtn';
import { StandardTruckCards, StandardTruckCardsSkeleton } from '../../../components/StandardTruckCards/StandardTruckCards';
import CustomIonicWrapper from '../../../components/CustomIonicWrapper/CustomIonicWrapper';
import CustomMuiInput from '../../../components/CustomMuiInput/CustomMuiInput';
import InfoModal from '../../../components/InfoModal/InfoModal';
import EditModal from '../../../components/EditModal/EditModal';
import DispatchFeeTypeToggle from '../../../components/DispatchFeeTypeToggle/DispatchFeeTypeToggle';
import CustomAlert from '../../../components/CustomAlert/CustomAlert';
import NoDataImage from '../../../components/NoDataImage/NoDataImage';
// ICONs & SVGs
import { addCircleOutline } from 'ionicons/icons';
import addTruck from '../../../imgs/homePage/add-truck.svg'

function AllTrucks() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { truckInfo1_done, truckInfo2_done, truckInfo3_done } = useSelector(s => s.truck);
    const allTrucks = useSelector(s => s.truck.getAllTrucks);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);
    // Modal
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState({});
    // Edit Modal
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editmodalData, setEditModalData] = useState({
        admin_cost: '', apportion_plate_cost: '', approx_miles_per_year: '', county_tax: '', dispatch_fee: '',
        factoring_rate: '', fuel_mileage: '', insurance: '', loan_amount_to_bank: '', misc: '', pm: '', tolls: '',
        truck_no: '', factoring_rate: '', dispatch_fee: '', carrier_authority: ''
    });
    const [dispatchFeeType, setDispatchFeeType] = useState(false);
    const [carrierAuthFeeType, setCarrierAuthFeeType] = useState(true);
    const [editloading, setEditLoading] = useState(false);
    // Delete Data
    const [deleteID, setDeleteID] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    // Alert
    const [showAlert, setShowAlert] = useState(false);

    useEffect(async () => {
        if (location.pathname == '/all-trucks') {
            if (!await checkUserId()) return history.push('/login');
            fetchAllTrucks();
        }
    }, [location.pathname])

    const fetchAllTrucks = async () => {
        if (!await checkUserId()) return;

        const body = { user_id: await checkUserId() }

        await axios.post(`${process.env.REACT_APP_BASEURL}/truck/all-trucks`, body)
            .then(res => {
                if (res.data.success) {
                    dispatch({ type: FETCH_ALL_TRUCKS, payload: res.data.data })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }

    const onProfileCardClick = (data) => {
        setModalData(data);
        setOpenModal(true);
    }

    const onAddTruck = async () => {
        if (!await checkUserId()) return;

        if (truckInfo3_done) {
            history.push('/truck-information/review');
        } else if (truckInfo2_done) {
            history.push('/truck-information/3');
        } else if (truckInfo1_done) {
            history.push('/truck-information/2');
        } else {
            history.push('/truck-information');
        }
    }

    const onCardDelete = async () => {
        if (!deleteID) return enqueueSnackbar('Something Went Wrong, Try Again !!!!', { variant: 'error' })
        const body = { truck_id: deleteID }
        setDeleteLoading(true);

        await axios.put(`${process.env.REACT_APP_BASEURL}/truck/delete`, body)
            .then(res => {
                if (res.data.success) {
                    const updatedArr = [...allTrucks].filter(u => u.truck_id !== deleteID);
                    dispatch({ type: FETCH_ALL_TRUCKS, payload: updatedArr })
                    enqueueSnackbar('Truck Deleted Successfully', { variant: 'success' })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setDeleteID(null);
                setDeleteLoading(false);
            })
            .catch(err => {
                console.log(err);
                setDeleteID(null);
                setDeleteLoading(false);
            })
    }

    const onCardEditClick = (e, data) => {
        e.stopPropagation();
        setOpenEditModal(true);
        setEditModalData({ ...editmodalData, ...data });
        setDispatchFeeType(data.dispatch_fee_type === '$' ? false : true)
        setCarrierAuthFeeType(data.carrier_authority_type === '$' ? false : true)
    }

    const onUpdateData = async () => {
        const body = { ...editmodalData, dispatch_fee_type: dispatchFeeType ? '%' : '$', carrier_authority_type: carrierAuthFeeType ? '%' : '$' }

        const {
            apportion_plate_cost, insurance, loan_amount_to_bank, county_tax, tolls, admin_cost, approx_miles_per_year,
            pm, misc, fuel_mileage
        } = body;

        if (!apportion_plate_cost || !insurance || !loan_amount_to_bank || !county_tax || !tolls || !admin_cost || !approx_miles_per_year || !pm || !misc || !fuel_mileage) return enqueueSnackbar('Please Fill All the Required Fields !!!', { variant: 'error' });

        setEditLoading(true);
        await axios.put(`${process.env.REACT_APP_BASEURL}/truck/update`, body)
            .then(res => {
                if (res.data.success) {
                    const arr = [];
                    allTrucks.forEach(a => {
                        if (a.truck_id == body.truck_id) {
                            arr.push(body)
                        } else {
                            arr.push(a)
                        }
                    })

                    dispatch({ type: FETCH_ALL_TRUCKS, payload: arr })
                    enqueueSnackbar('Truck Updated Successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setOpenEditModal(false);
                return setEditLoading(false);
            })
            .catch(err => {
                console.log(err);
                return setEditLoading(false);
            })
    }

    const onAlertDismiss = (e) => {
        if (e.detail.role == 'confirm') {
            setShowAlert(false);
            onCardDelete();
        } else {
            setShowAlert(false);
            setDeleteID(null);
        }
    }

    return (
        <CustomIonicWrapper title='My Trucks' onBackClick={() => history.push('/home')}>
            <CustomAlert open={showAlert} header='Are You Sure ?' onDismiss={onAlertDismiss} message='On Confirm All Data Will Be Deleted' />

            <section className={`profile_section ${isPlatform('ios') ? 'profile_section_ios' : ''}`} >
                <div className='profile_container' style={{ overflow: loading ? 'hidden' : 'auto' }} >
                    {loading ? (
                        <>{new Array(10).fill('').map((a, i) => <StandardTruckCardsSkeleton key={i} />)}</>
                    ) : (
                        <>
                            {allTrucks?.length > 0 ? (
                                <>
                                    {allTrucks?.map((a, i) => (
                                        <StandardTruckCards key={i} heading={a.truck_no} onClick={() => onProfileCardClick(a)}
                                            onDel={(e) => { e.stopPropagation(); setDeleteID(a.truck_id); setShowAlert(true) }}
                                            delLoading={deleteLoading && deleteID === a.truck_id}
                                            onEdit={(e) => onCardEditClick(e, a)}
                                        />
                                    )
                                    )}
                                </>
                            ) : (
                                <div style={{ height: '100%', display: 'flex' }} >
                                    <NoDataImage img={addTruck} heading='Add Some Trucks' />
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className="profile_StandardAndroidBtn_cont">
                    <StandardAndroidBtn iconLeft={addCircleOutline} label='Add Truck' onClick={onAddTruck} />
                </div>
            </section>

            {/* SHOWING DATA */}
            <InfoModal open={openModal} setOpen={setOpenModal} >
                <p className='infoModal_header'>Details are Per Month</p>

                <div className="profile_truckModal_data_header">
                    <p>Truck Number :</p>
                    <h3>{modalData.truck_no}</h3>
                </div>
                {/* <ProfileModalData heading='Truck Number' amt={modalData.truck_no} /> */}
                <ProfileModalData heading='Admin Cost' amt={modalData.admin_cost} dollar />
                <ProfileModalData heading='Apportion Plate Cost' amt={modalData.apportion_plate_cost} dollar />
                <ProfileModalData heading='Approx Miles Per Year' amt={modalData.approx_miles_per_year} />
                <ProfileModalData heading='County Tax' amt={modalData.county_tax} dollar />
                <ProfileModalData heading='Insurance' amt={modalData.insurance} dollar />
                <ProfileModalData heading='Bank Loan' amt={modalData.loan_amount_to_bank} dollar />
                <ProfileModalData heading='Miscellaneous' amt={modalData.misc} dollar />
                <ProfileModalData heading='Preventive Maintenance (PM)' amt={modalData.pm} dollar />
                <ProfileModalData heading='Tolls' amt={modalData.tolls} dollar />
                <ProfileModalData heading='Fuel Mileage' amt={`${modalData.fuel_mileage} MPG`} />
                {modalData.factoring_rate > 0 && <ProfileModalData heading='Factoring Rate %' amt={`${modalData.factoring_rate} %`} />}
                {modalData.dispatch_fee > 0 && <ProfileModalData heading='Dispatch Fee' amt={`${modalData.dispatch_fee_type === '$' ? '$' : ''} ${modalData.dispatch_fee} ${modalData.dispatch_fee_type === '%' ? '%' : ''}`} />}
                {modalData.carrier_authority > 0 && <ProfileModalData heading='Carrier Authority' amt={`${modalData.carrier_authority_type === '$' ? '$' : ''} ${modalData.carrier_authority} ${modalData.carrier_authority_type === '%' ? '%' : ''}`} />}
                {modalData.ifta_ucr && <ProfileModalData heading='IFTA / UCR / 2290' amt={modalData.ifta_ucr} dollar />}
            </InfoModal>

            {/* EDITING DATA */}
            <EditModal open={openEditModal} setOpen={setOpenEditModal} onUpdate={onUpdateData} loading={editloading} >
                <p className='infoModal_header'>Details are Per Month</p>

                {/* <CustomMuiInput lable='Truck Number' val={editmodalData.truck_no} name='truck_no' editValData={editmodalData} setVal={setEditModalData} mt={15} readOnly shrink /> */}
                <div className="profile_truckModal_data_header profile_editTruckModal_data_header">
                    <p>Truck Number :</p>
                    <h3>{editmodalData.truck_no}</h3>
                </div>
                <CustomMuiInput lable='Admin Cost' val={editmodalData.admin_cost} name='admin_cost' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment='$' />
                <CustomMuiInput lable='Apportion Plate Cost' val={editmodalData.apportion_plate_cost} name='apportion_plate_cost' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment='$' />
                <CustomMuiInput lable='Approx Miles Per Year' val={editmodalData.approx_miles_per_year} name='approx_miles_per_year' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' shrink />
                <CustomMuiInput lable='County Tax' val={editmodalData.county_tax} name='county_tax' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment='$' />
                <CustomMuiInput lable='Insurance' val={editmodalData.insurance} name='insurance' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment='$' />
                <CustomMuiInput lable='Bank Loan' val={editmodalData.loan_amount_to_bank} name='loan_amount_to_bank' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment='$' />
                <CustomMuiInput lable='Miscellaneous' val={editmodalData.misc} name='misc' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment='$' />
                <CustomMuiInput lable='Preventive Maintenance (PM)' val={editmodalData.pm} name='pm' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment='$' />
                <CustomMuiInput lable='Tolls' val={editmodalData.tolls} name='tolls' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment='$' />
                <CustomMuiInput lable='Fuel Mileage (Average Miles Per Gallon)' val={editmodalData.fuel_mileage} name='fuel_mileage' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment='MPG' />
                <CustomMuiInput lable='Factoring Rate %' val={editmodalData.factoring_rate} name='factoring_rate' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment='%' />
                <DispatchFeeTypeToggle checked={dispatchFeeType} setChecked={setDispatchFeeType} />
                <CustomMuiInput lable='Dispatch Fee' val={editmodalData.dispatch_fee} name='dispatch_fee' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment={dispatchFeeType ? '%' : '$'} />
                <DispatchFeeTypeToggle checked={carrierAuthFeeType} setChecked={setCarrierAuthFeeType} />
                <CustomMuiInput lable='Carrier Authority' val={editmodalData.carrier_authority} name='carrier_authority' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment={carrierAuthFeeType ? '%' : '$'} />
                <CustomMuiInput lable='IFTA / UCR / 2290' val={editmodalData.ifta_ucr || ''} name='ifta_ucr' editValData={editmodalData} setVal={setEditModalData} mt={15} type='number' startAdornment='$' />

            </EditModal>
            {/*  */}
        </CustomIonicWrapper>
    )
}

export default AllTrucks

const ProfileModalData = ({ heading, amt, dollar }) => (
    <div className='profile_truckModal_data'>
        <h3>{heading}</h3>
        <p>{dollar ? '$' : ''} {amt}</p>
    </div>
)