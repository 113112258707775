import { useEffect, useState } from "react";
import './TripDocuments.css';
import { useHistory, useLocation } from "react-router"
import { IonRippleEffect } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_ALL_TRIPS } from "../../../redux/type";
import { Skeleton } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
// Storage
import { checkUserId } from "../../../redux/actions/exportFunctions";
// Components
import CustomIonicWrapper from "../../../components/CustomIonicWrapper/CustomIonicWrapper"
import NoDataImage from "../../../components/NoDataImage/NoDataImage";
// ICONs & SVGs
import docImg from '../../../imgs/document.svg'
import viewTrips from '../../../imgs/homePage/view-trips.svg'

function TripDocuments() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const allTrips = useSelector(s => s.trip.getAllTrips);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        if (location.pathname == '/trip-documents') {
            if (!await checkUserId()) return history.push('/login');
            fetchAllTripsDocuments();
        }
    }, [location.pathname])

    const fetchAllTripsDocuments = async () => {
        if (!await checkUserId()) return;

        const body = { user_id: await checkUserId() }

        await axios.post(`${process.env.REACT_APP_BASEURL}/trip/all-trips`, body)
            .then(res => {
                if (res.data.success) {
                    dispatch({ type: FETCH_ALL_TRIPS, payload: res.data.data })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }

    const onDocClick = (heading, tripId) => {
        const body = { heading, tripId: tripId || '' }
        history.push('/view-documents', { body })
    }

    return (
        <CustomIonicWrapper title='Trip Documents' onBackClick={() => history.push('/all-documents')}>
            <div className="myDocs_container">
                <section className="myDocs_section">
                    {allTrips?.length > 0 ? (
                        <div className="myDocs_docCard_cont">
                            {!loading ? (
                                <>
                                    {allTrips?.map(t => (
                                        <DocumentCard heading={`Trip: ${t.trip_number}`} key={t.trip_number}
                                            onClick={() => onDocClick(`Trip: ${t.trip_number}`, t.trip_id)}
                                        />
                                    ))}
                                </>
                            ) : (
                                <>{Array(8).fill('').map((a, i) => <DocumentCardSkeleton key={i} />)}</>
                            )}
                        </div>
                    ) : (
                        <NoDataImage img={viewTrips} heading='Add Some Trips' />
                    )}
                </section>
            </div>
        </CustomIonicWrapper>
    )
}

export default TripDocuments

const DocumentCard = ({ heading, onClick }) => (
    <div className="myDocs_docCard ion-activatable" onClick={onClick}>
        <img src={docImg} alt="Document" />
        <h4>{heading}</h4>
        <IonRippleEffect />
    </div>
)

const DocumentCardSkeleton = () => (
    <div className="myDocs_docCard">
        <Skeleton variant="rounded" width={70} height={70} sx={{ margin: '5px auto' }} animation="wave" />
        <Skeleton variant="rounded" width='100%' height={10} sx={{ marginTop: 1.5 }} animation="wave" />
        <Skeleton variant="rounded" width='75%' height={10} sx={{ margin: 'auto', marginTop: 1 }} animation="wave" />
    </div>
)
