import './DispatchFeeTypeToggle.css';
import { IonToggle } from "@ionic/react"

function DispatchFeeTypeToggle({ checked, setChecked }) {
    return (
        <div className='dispatchFeeTypeToggle' >
            <h2>$</h2>
            <IonToggle mode='ios' checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
            <h2>%</h2>
        </div>
    )
}

export default DispatchFeeTypeToggle