import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { CircularProgress } from '@mui/material';
import './StandardAndroidBtn.css';
<CircularProgress style={{ color: '#fff' }} size={22} />

function StandardAndroidBtn({ label, iconLeft, iconRight, onClick, disabled, loading }) {
    return (
        <IonButton mode='md' expand='block' className='standardAndroidBtn' onClick={onClick} disabled={disabled || loading} >
            {iconLeft && <IonIcon slot="start" icon={iconLeft} />}
            {!loading ? (
                <>{label}</>
            ) : (
                <IonSpinner name="crescent" style={{ color: '#fff' }} />
            )}
            {iconRight && <IonIcon slot="end" icon={iconRight} />}
        </IonButton>
    )
}

export default StandardAndroidBtn