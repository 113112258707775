import { IonContent, IonPage } from '@ionic/react';
import './CustomIonicWrapper.css';
import StandardHeader from '../StandardHeader/StandardHeader';

function CustomIonicWrapper({ title, onBackClick, showNav, showNavRight, headerFontSize, children }) {
    return (
        <IonPage>
            <StandardHeader title={title} onBackClick={onBackClick} showNav={showNav} showNavRight={showNavRight} headerFontSize={headerFontSize} />

            <IonContent fullscreen className='customIonicWrapper_content' >
                {children}
            </IonContent>
        </IonPage>
    )
}

export default CustomIonicWrapper