import './NoDataImage.css';

function NoDataImage({ img, heading, subHeading }) {
    return (
        <div className='noData_img_cont' >
            <img src={img} />
            <h3>{heading}</h3>
            {subHeading && <h3 style={{ margin: '-5px 0 0' }} >{subHeading}</h3>}
        </div>
    )
}

export default NoDataImage