import { useHistory, useLocation } from 'react-router-dom';
import './Menu.css';
import { IonContent, IonIcon, IonItem, IonLabel, IonMenu } from '@ionic/react';
import {
  personCircle, homeOutline, calculatorOutline, constructOutline, notificationsOutline, documentTextOutline,
  rocketOutline, carOutline
} from 'ionicons/icons';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';

const Menu = () => {
  const location = useLocation();
  const history = useHistory();
  const showDesktopNav = useMediaQuery('(min-width: 992px)')
  const userLoggedIn = useSelector(s => s.user.userLoggedIn);

  const showNavFunc = () => {
    if (userLoggedIn) {
      if (showDesktopNav) {
        // For desktop
        if (location.pathname === '/' || location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/forgot-password') {
          return true
        } else {
          return false
        }
      } else if (!showDesktopNav && location.pathname === '/home') {
        // For Mobile on only home page
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  return (
    <IonMenu contentId="main" type="overlay" className='menuCont' disabled={showNavFunc()} >
      <IonContent>
        <h1 className='menu_header' >
          My Truck Profit
        </h1>

        {appPages?.map((p, i) => (
          <IonItem button detail={false} lines='none' key={i} onClick={() => history.push(p.url)}
            className={`menu_ionItem ${location.pathname === p.url && 'menu_ionItem_active'}`}
          >
            <IonIcon icon={p.icons}></IonIcon>
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        ))}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

const appPages = [
  {
    title: 'Home',
    url: '/home',
    icons: homeOutline
  },
  {
    title: 'Calculate Profit',
    url: '/pay-driver',
    icons: calculatorOutline
  },
  {
    title: 'My Trucks',
    url: '/all-trucks',
    icons: carOutline
  },
  {
    title: 'My Documents',
    url: '/all-documents',
    icons: documentTextOutline
  },
  {
    title: 'My Trips',
    url: '/all-trips',
    icons: rocketOutline
  },
  {
    title: 'Services',
    url: '/services',
    icons: constructOutline
  },
  {
    title: 'My Notifications',
    url: '/notifications',
    icons: notificationsOutline
  },
  {
    title: 'My Account',
    url: '/account',
    icons: personCircle
  },
];