import './Subscription.css';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSnackbar } from 'notistack';
import { Button, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserId } from '../../redux/actions/exportFunctions';
import { UPDATE_PAYPAL_SUB_STATUS } from '../../redux/type';
import { isPlatform } from '@ionic/react';
import moment from 'moment';
import axios from 'axios';
// IAP
import { Glassfy } from "capacitor-plugin-glassfy";
// Components
import CustomIonicWrapper from '../../components/CustomIonicWrapper/CustomIonicWrapper'
import InfoModal from '../../components/InfoModal/InfoModal';
// ICONs & SVGs
import cancel from '../../imgs/subscription/cancel-subscription.gif'
import success from '../../imgs/subscription/success-subscription.gif'

const paypalScript = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    components: "buttons",
    intent: "subscription",
    vault: true,
}

function Subscription() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const companyData = useSelector(s => s.user.companyData);
    const subscriptionData = useSelector(s => s.user.subscriptionData);
    const paypalSubscriptionStatus = useSelector(s => s.user.paypalSubscriptionStatus);
    const paypalSubscriptionData = useSelector(s => s.user.paypalSubscriptionData);

    const [loading, setLoading] = useState(false);
    const [openPaypalCancelModal, setOpenPaypalCancelModal] = useState(false);
    const [openPaypalSuccessModal, setOpenPaypalSuccessModal] = useState(false);
    const [cancelSubsloading, setCancelSubsLoading] = useState(false);
    const [activateSubsloading, setActivateSubsLoading] = useState(false);
    const [iosIAPloading, setIosIAPloading] = useState(false);
    // IAP
    const [iapSubscription, setIapSubscription] = useState({});

    useEffect(async () => {
        if (Object?.values(companyData)?.includes('') || Object?.values(subscriptionData)?.includes('')) return history.push('/home')
        initializeGlassify();
    }, [])

    const onPaypalApprove = async (data) => {
        if (!await checkUserId()) return enqueueSnackbar('No User Found', { variant: 'error' });

        const body = { user_id: await checkUserId(), company_details: companyData, paypal_data: data }
        await axios.post(`${process.env.REACT_APP_BASEURL}/subscription/add`, body)
            .then(res => {
                if (res.data.success) {
                    setOpenPaypalSuccessModal(true);
                    dispatch({ type: UPDATE_PAYPAL_SUB_STATUS, payload: 'ACTIVE' })
                    setTimeout(() => {
                        history.push('/home')
                        enqueueSnackbar('User Subscribed Successfully !!!', { variant: 'success' })
                    }, 3000);
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onPauseSubscription = async () => {
        setCancelSubsLoading(true);

        await axios.post('https://api-m.paypal.com/v1/oauth2/token', new URLSearchParams({ 'grant_type': 'client_credentials' }),
            {
                headers: { 'Accept': 'application/json', 'Accept-Language': 'en_US' },
                auth: { username: process.env.REACT_APP_PAYPAL_CLIENT_ID, password: process.env.REACT_APP_PAYPAL_SECRET }
            })
            .then(async res => {
                // Pause Subsscription
                await axios.post(`https://api-m.paypal.com/v1/billing/subscriptions/${JSON.parse(subscriptionData?.paypal_data)?.subscriptionID}/suspend`, {}, { // Check Status
                    headers: { "Authorization": `Bearer ${res.data.access_token}` }
                })
                    .then(res => {
                        updateSubscriptionStatusinDB(0);
                    })
                    .catch(err => {
                        console.log(err, 'Error @Paypal Pause Subscription')
                        setCancelSubsLoading(false);
                    })
            })
            .catch(err => {
                setCancelSubsLoading(false);
                console.log(err, 'Error @Paypal Access Token')
            })
    }

    const onActivateSubscription = async () => {
        setActivateSubsLoading(true);

        await axios.post('https://api-m.paypal.com/v1/oauth2/token', new URLSearchParams({ 'grant_type': 'client_credentials' }),
            {
                headers: { 'Accept': 'application/json', 'Accept-Language': 'en_US' },
                auth: { username: process.env.REACT_APP_PAYPAL_CLIENT_ID, password: process.env.REACT_APP_PAYPAL_SECRET }
            })
            .then(async res => {
                // Pause Subsscription
                await axios.post(`https://api-m.paypal.com/v1/billing/subscriptions/${JSON.parse(subscriptionData?.paypal_data)?.subscriptionID}/activate`, {}, { // Check Status
                    headers: { "Authorization": `Bearer ${res.data.access_token}` }
                })
                    .then(res => {
                        updateSubscriptionStatusinDB(1);
                    })
                    .catch(err => {
                        console.log(err, 'Error @Paypal Active Subscription')
                        enqueueSnackbar('Something Went Wrong Please Try Again !!!', { variant: 'error' })
                        dispatch({ type: UPDATE_PAYPAL_SUB_STATUS, payload: undefined })
                        setActivateSubsLoading(false);
                    })
            })
            .catch(err => {
                setActivateSubsLoading(false);
                enqueueSnackbar('Something Went Wrong Please Try Again !!!', { variant: 'success' })
                console.log(err, 'Error @Paypal Access Token')
            })
    }

    const updateSubscriptionStatusinDB = async (status) => {
        if (!await checkUserId()) return;

        const body = { user_id: await checkUserId(), sub_id: subscriptionData.sub_id, status }

        await axios.post(`${process.env.REACT_APP_BASEURL}/subscription/update-active-status`, body)
            .then(res => {
                if (res.data.success) {
                    if (status == 1) {
                        // Functions for on activate subscription
                        setActivateSubsLoading(false);
                        setOpenPaypalSuccessModal(true)
                        enqueueSnackbar('Subscribed Again Successfully !!!', { variant: 'success' })
                        dispatch({ type: UPDATE_PAYPAL_SUB_STATUS, payload: 'ACTIVE' })
                        setTimeout(() => history.push('/home'), 3000);
                    } else {
                        // Functions for on pause subscription
                        setCancelSubsLoading(false);
                        enqueueSnackbar('Unsubscribed Successfully !!!', { variant: 'success' })
                        history.push('/home')
                        dispatch({ type: UPDATE_PAYPAL_SUB_STATUS, payload: 'SUSPENDED' })
                    }
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
            })
            .catch(err => {
                console.log(err);
                setCancelSubsLoading(false);
                setActivateSubsLoading(false);
            })
    }

    const onSubscriptionClick = async () => {
        try {
            setIosIAPloading(true);

            const transaction = await Glassfy.purchaseSku({ sku: iapSubscription?.skus[0] })
            const permission = transaction.permissions.all?.find(p => p?.permissionId === 'Premium - $14.99')

            if (transaction.receiptValidated && permission && permission?.isValid) {
                onPaypalApprove({ transaction, sku: iapSubscription?.skus[0] })
                setIosIAPloading(false);
            }
        } catch (e) {
            console.log(e);
            setIosIAPloading(false);
            setOpenPaypalCancelModal(true);
        }
    }

    const initializeGlassify = async () => {
        if (!isPlatform('ios')) return;

        try {
            await Glassfy.initialize({ apiKey: process.env.REACT_APP_GLASSIFY_APIKEY, watcherMode: false });

            const offerings = await Glassfy.offerings();
            const subscription_offering = offerings.all?.find(o => o?.offeringId === 'Subscriptions')
            setIapSubscription(subscription_offering);

            // const permissions = await Glassfy.permissions();
            // const permission = permissions.all?.find(p => p?.permissionId === 'Premium - $14.99')            
            // console.log(permission);
        } catch (e) {
            console.log('error', e);
            // enqueueSnackbar('IAP only available on IOS devices !!!', { variant: 'error' })
        }
    }

    return (
        <CustomIonicWrapper title='Subscription' onBackClick={() => history.push('/home')}>
            <div className="subscription_cont">
                <section className="subscription_section">
                    {loading ? (
                        <CircularProgress sx={{ color: '#00235F' }} />
                    ) : (
                        <div className="subscription_data">
                            <div className="subscription_data_card_cont">
                                <SubscriptionCard_Free active={subscriptionData?.active == 1 && subscriptionData?.type_of_subscription == 'Free'}
                                    validity={subscriptionData?.active == 1 ? subscriptionData?.validity : null}
                                />

                                <SubscriptionCard_Paid active={subscriptionData?.active == 1}
                                    validity={paypalSubscriptionData?.permission ? paypalSubscriptionData?.permission?.isValid && paypalSubscriptionData?.permission?.expireDate && paypalSubscriptionData?.permission?.expireDate
                                        : paypalSubscriptionData?.billing_info?.next_billing_time && paypalSubscriptionStatus == 'ACTIVE' ?
                                            paypalSubscriptionData?.billing_info?.next_billing_time : false
                                    }
                                />
                            </div>

                            {isPlatform('ios') ? (
                                <Button variant='contained' disableElevation className='subscription_unsubscribeBtn' fullWidth
                                    size='large' onClick={onSubscriptionClick} disabled={iosIAPloading}
                                >
                                    {iosIAPloading ? <CircularProgress sx={{ color: '#fff' }} size={16} /> : 'Go Premium'}
                                </Button>
                            ) : (
                                <>
                                    {paypalSubscriptionStatus == 'ACTIVE' ? (
                                        <Button variant='contained' disableElevation className='subscription_unsubscribeBtn' fullWidth
                                            size='large' onClick={onPauseSubscription} disabled={cancelSubsloading}
                                        >
                                            {cancelSubsloading ? <CircularProgress sx={{ color: '#fff' }} size={22} /> : (
                                                <>
                                                    {paypalSubscriptionStatus == 'ACTIVE' ? 'Unsubscribe' : paypalSubscriptionStatus == 'SUSPENDED' ? 'Subscribe Again' : 'Subscribe'}
                                                </>
                                            )}
                                        </Button>
                                    ) : paypalSubscriptionStatus == 'SUSPENDED' ? (
                                        <Button variant='contained' disableElevation className='subscription_unsubscribeBtn' fullWidth
                                            size='large' onClick={onActivateSubscription} disabled={activateSubsloading}
                                        >
                                            {activateSubsloading ? <CircularProgress sx={{ color: '#fff' }} size={22} /> : (
                                                <>
                                                    {paypalSubscriptionStatus == 'SUSPENDED' ? 'Subscribe Again For $14.99' : 'Subscribe'}
                                                </>
                                            )}
                                        </Button>
                                    ) : (
                                        <PayPalScriptProvider options={paypalScript}>
                                            <PayPalButtons
                                                className='subscription_paypal_btns'
                                                style={{ label: 'paypal', shape: 'pill', layout: 'vertical' }}

                                                createSubscription={(data, actions) => {
                                                    return actions.subscription
                                                        .create({
                                                            plan_id: process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_ID,
                                                        })
                                                    // .then((orderId) => {
                                                    //     console.log(orderId);
                                                    //     return orderId;
                                                    // })
                                                }}

                                                onApprove={(data, actions) => {
                                                    console.log('on Approve', data);
                                                    console.log(actions, 'actions');
                                                    onPaypalApprove(data)
                                                }}

                                                onCancel={(data, actions) => {
                                                    console.log(data, 'cancel');
                                                    console.log(actions, 'cancel');
                                                    setOpenPaypalCancelModal(true);
                                                }}

                                                onError={(err) => {
                                                    console.log(err, 'error')
                                                    setOpenPaypalCancelModal(true);
                                                }}
                                            />
                                        </PayPalScriptProvider>
                                    )}
                                </>
                            )}

                            <InfoModal open={openPaypalCancelModal} setOpen={setOpenPaypalCancelModal}>
                                <div className='subscription_cancelModal_cont' >
                                    <img src={cancel} />
                                    <h3>Please Try Again, Transaction Cancelled !!!</h3>
                                </div>
                            </InfoModal>

                            <InfoModal open={openPaypalSuccessModal} setOpen={setOpenPaypalSuccessModal}>
                                <div className='subscription_cancelModal_cont subscription_successModal_cont' >
                                    <img src={success} />
                                    <h3>Transaction Successfull !!!</h3>
                                </div>
                            </InfoModal>
                        </div>
                    )}
                </section>
            </div>
        </CustomIonicWrapper>
    )
}

export default Subscription

const SubscriptionCard_Free = ({ validity, active }) => (
    <div className={`subscription_data_card1 ${active ? 'subscription_data_card_active' : ''}`}>
        <h4>Free</h4>
        <h6>30 Days Trial</h6>

        <div className='subscription_data_card1_list' >
            <p>Calculate Trip Profit (30 Days)</p>
            <p>Maintain Truck Information</p>
            <p>Keep all your Documents in one Place (6 months)</p>
            <p>Maintain Trips Information</p>
        </div>

        {validity ? (
            <div className="subscription_data_card1_expiryCont">
                <p>{moment(new Date()).isBefore(validity) ? 'Expiry at:' : 'Expired on:'} {moment(validity).format('MMM Do, YYYY')}</p>
            </div>
        ) : (
            <div className="subscription_data_card1_expiryCont">
                <p>Expired</p>
            </div>
        )}
    </div >
)

const SubscriptionCard_Paid = ({ validity, active }) => {
    const paypalSubscriptionStatus = useSelector(s => s.user.paypalSubscriptionStatus);
    return (
        <div className={`subscription_data_card1 subscription_data_card2 ${active ? 'subscription_data_card_active' : ''}`}>
            <h6 className='subscription_data_card_goPremium' >
                {paypalSubscriptionStatus == 'ACTIVE' ? 'Subscribed' : paypalSubscriptionStatus == 'SUSPENDED' ? 'Subscribe Again' : 'Go Premium'}
            </h6>
            <h4>Benefits of Premium</h4>

            <div className='subscription_data_card1_list' >
                {isPlatform('ios') && (
                    <>
                        <p>Length of subscription: 1 Month</p>
                        <p>Price of subscription: $14.99</p>
                    </>
                )}
                <p>Calculate Trip Profit (Unlimited)</p>
                <p>Maintain Trucks Information</p>
                <p>Keep all your Documents in one Place (Unlimited)</p>
                <p>Maintain Trips Information</p>
            </div>

            {validity && (
                <div className="subscription_data_card1_expiryCont">
                    <p>{moment(new Date()).isBefore(validity) ? 'Expiry at:' : 'Expired on:'} {moment(validity).format('MMM Do, YYYY')}</p>
                </div>
            )}
        </div >
    )
}