import { useEffect, useState } from 'react';
import '../TruckInfo1/TruckInfo1.css';
import './TruckInfo2.css';
import { useHistory, useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TRUCK_INFO_2 } from '../../../redux/type';
// Components
import CustomIonicWrapper from '../../../components/CustomIonicWrapper/CustomIonicWrapper';
import CustomMuiInput from '../../../components/CustomMuiInput/CustomMuiInput';
import DispatchFeeTypeToggle from '../../../components/DispatchFeeTypeToggle/DispatchFeeTypeToggle';
// ICONs & SVGs
import InfoPageTempelate from '../../../components/InfoPageTempelate/InfoPageTempelate';

function TruckInfo2() {
    const history = useHistory();
    const dipatch = useDispatch();
    const { pathname } = useLocation();
    const truck = useSelector(s => s.truck);
    const { enqueueSnackbar } = useSnackbar();

    const [countryTax, setCountryTax] = useState('');
    const [apportionPlate, setApportionPlate] = useState('');
    const [adminCost, setAdminCost] = useState('');
    const [factoringRate, setFactoringRate] = useState('');
    const [dispatchFee, setDispatchFee] = useState('');
    const [dispatchFeeType, setDispatchFeeType] = useState(false);

    useEffect(() => {
        if (pathname == '/truck-information/2') {
            setCountryTax(truck.truckData.county_tax);
            setApportionPlate(truck.truckData.apportion_plate_cost);
            setAdminCost(truck.truckData.admin_cost);
            setFactoringRate(truck.truckData.factoring_rate);
            setDispatchFee(truck.truckData.dispatch_fee);
            setDispatchFeeType(truck.truckData.dispatch_fee_type);
        }
    }, [pathname])

    const onNextTruckInfo2 = () => {
        if (!countryTax) return enqueueSnackbar('CountyTax Not Found, Enter 0 IF Not Applicable !!!', { variant: 'error' })
        if (!apportionPlate) return enqueueSnackbar('Apportion Plate Cost Not Found, Enter 0 IF Not Applicable !!!', { variant: 'error' })
        if (!adminCost) return enqueueSnackbar('Admin Cost Not Found, Enter 0 IF Not Applicable !!!', { variant: 'error' })

        dipatch({
            type: ADD_TRUCK_INFO_2,
            truckPayloadData: {
                county_tax: countryTax, apportion_plate_cost: apportionPlate, admin_cost: adminCost,
                factoring_rate: factoringRate, dispatch_fee: dispatchFee, dispatch_fee_type: dispatchFeeType
            },
            truckInfoDone2: true
        })

        return history.push('/truck-information/3');
    }

    return (
        <CustomIonicWrapper title='Truck Information' onBackClick={() => history.push('/all-trucks')}>
            <div className='truckInfo_cont' >
                <div className='truckInfo_stepImg truckInfo_stepImg2' ></div>

                <InfoPageTempelate btn1Label='Back' btn1Click={() => history.push('/truck-information')}
                    btn2Click={onNextTruckInfo2} arrowClass='truckInfo_data_arrow2' pageTempelateHeader='Enter Details Per Month'
                >
                    <CustomMuiInput lable='County Tax' val={countryTax} setVal={setCountryTax} type='number' startAdornment='$' />
                    <CustomMuiInput lable='Apportion Plate Cost' val={apportionPlate} setVal={setApportionPlate} type='number' startAdornment='$' />
                    <CustomMuiInput lable='Admin Cost' val={adminCost} setVal={setAdminCost} type='number' startAdornment='$' />
                    <CustomMuiInput lable='Factoring Rate %' val={factoringRate} setVal={setFactoringRate} type='number' startAdornment='%' />
                    <DispatchFeeTypeToggle checked={dispatchFeeType} setChecked={setDispatchFeeType} />
                    <CustomMuiInput lable='Dispatch Fee' val={dispatchFee} setVal={setDispatchFee} type='number' startAdornment={dispatchFeeType ? '%' : '$'} />
                </InfoPageTempelate>
            </div>
        </CustomIonicWrapper>
    )
}

export default TruckInfo2