import { Preferences } from "@capacitor/preferences";

export const checkUserData = async () => {
    const { value } = await (Preferences?.get({ key: 'truckola_users' }))
    return !!value ? JSON.parse(value) : null;
};

export const checkUserId = async () => {
    const { value } = await (Preferences.get({ key: 'truckola_users' }))
    return JSON.parse(value).user_id;
};

// IONIC MOBILE ROUTING
{/* <>
    <Route path="/" exact={true} render={() => !userLoggedIn ? <LandingPage /> : <Redirect to='/home' />} />
    <Route path="/login" exact={true} component={Login} />
    <Route path="/signup" exact={true} component={Signup} />
    <Route path="/change-password/:token" exact={true} component={ChangePassword} />
    <Route path="/forgot-password" exact={true} component={ForgotPassword} />
    <Route path="/home" exact={true} component={HomePage} />
    <Route path="/services" exact={true} component={Services} />
    <Route path="/notifications" exact={true} component={Notifications} />
    <Route path="/account" exact={true} component={MyAccount} />
    <Route path="/subscription" exact={true} component={Subscription} />
    <Route path="/all-trucks" exact={true} component={AllTrucks} />
    <Route path="/truck-information" exact={true} component={TruckInfo1} />
    <Route path="/truck-information/2" exact={true} component={TruckInfo2} />
    <Route path="/truck-information/3" exact={true} component={TruckInfo3} />
    <Route path="/truck-information/review" exact={true} component={ReviewInputs} />
    <Route path="/all-trips" exact={true} component={MyTrips} />
    <Route path="/my-trips/1" exact={true} component={MyTripInfo1} />
    <Route path="/my-trips/2" exact={true} component={MyTripInfo2} />
    <Route path="/my-trips/3" exact={true} component={MyTripInfo3} />
    <Route path="/my-trips/review" exact={true} component={ReviewMyTripData} />
    <Route path="/pay-driver" exact={true} component={PayYourDriver} />
    <Route path="/calculate-profit" exact={true} component={CalculateTripProfilt} />
    <Route path="/all-documents" exact={true} component={AllDocuments} />
    <Route path="/my-documents" exact={true} component={MyDocuments} />
    <Route path="/trip-documents" exact={true} component={TripDocuments} />
    <Route path="/view-documents" exact={true} component={ViewDocument} />
    <Route path="/t&c" exact={true} component={TermsConditions} />
</> */}