import { useEffect, useState } from 'react';
import '../../TruckInformation/AllTrucks/AllTrucks.css';
import './MyTrips.css';
import { IonDatetime, IonIcon, isPlatform } from "@ionic/react"
import { useHistory, useLocation } from 'react-router';
import axios from 'axios';
import moment from 'moment';
import { Button, Checkbox, Fab, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { checkUserId } from '../../../redux/actions/exportFunctions';
import { useSnackbar } from 'notistack';
// Date Picker 
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// Redux
import { FETCH_ALL_TRIPS, FETCH_ALL_TRUCKS } from '../../../redux/type';
import { useDispatch, useSelector } from 'react-redux';
// Components
import StandardAndroidBtn from '../../../components/StandardAndroidBtn/StandardAndroidBtn';
import CustomIonicWrapper from '../../../components/CustomIonicWrapper/CustomIonicWrapper';
import InfoModal from '../../../components/InfoModal/InfoModal';
import EditModal from '../../../components/EditModal/EditModal';
import CustomMuiInput from '../../../components/CustomMuiInput/CustomMuiInput';
import CustomMuiInputNormal from '../../../components/CustomMuiInput/CustomMuiInputNormal';
import { StandardTruckCards, StandardTruckCardsSkeleton } from '../../../components/StandardTruckCards/StandardTruckCards';
import CustomAlert from '../../../components/CustomAlert/CustomAlert';
import NoDataImage from '../../../components/NoDataImage/NoDataImage';
// ICONs & SVGs
import { addCircleOutline, closeOutline, filterOutline, trashOutline } from 'ionicons/icons';
import viewTrips from '../../../imgs/homePage/view-trips.svg'

function MyTrips() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const allTrips = useSelector(s => s.trip.getAllTrips);
    const allTrucks = useSelector(s => s.truck.getAllTrucks);
    const { enqueueSnackbar } = useSnackbar();

    const { tripInfo1_done, tripInfo2_done, tripInfo3_done } = useSelector(s => s.trip)

    const [loading, setLoading] = useState(true);
    // Modal
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState({});
    // Edit Modal
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editloading, setEditLoading] = useState(false);
    const [editmodalData, setEditModalData] = useState({
        bill_of_lading: '', comments: '', date_of_trip: '', from_city: '', from_state: '', profit: '', rate: '',
        rate_confirmation: '', to_city: '', to_state: '', total_loaded_miles: '', trip_number: '', truck_id: '',
    });
    const [billOfLadingImage, setBillOfLadingImage] = useState('');
    const [billOfLadingImagePreview, setBillOfLadingImagePreview] = useState('');
    const [rateConfImage, setRateConfImage] = useState('');
    const [rateConfImagePreview, setRateConfImagePreview] = useState('');

    // Delete Data
    const [deleteID, setDeleteID] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    // Filter Modal
    const [filterTripsData, setFilterTripsData] = useState([]);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [filterTrucksLoading, setFilterTrucksLoading] = useState(false);
    //---- Truck
    const [filterTruckSelectCheckBox, setFilterTruckSelectCheckBox] = useState(false);
    const [filterTruckSelect, setFilterTruckSelect] = useState('');
    //---- City
    const [filterByCityCheckBox, setFilterByCityCheckBox] = useState(false);
    const [filterFromCity, setFilterFromCity] = useState('');
    const [filterToCity, setFilterToCity] = useState('');
    //---- Date
    const [filterByDateCheckBox, setFilterByDateCheckBox] = useState(false);
    const [filterFromdateTime, setFilterFromDateTime] = useState(moment(new Date).format("yyyy-MM-DD"));
    const [showFromDateTime, setShowFromDateTime] = useState(false);
    const [filterTodateTime, setFilterToDateTime] = useState(moment(new Date).format("yyyy-MM-DD"));
    const [showToDateTime, setShowToDateTime] = useState(false);
    const [filterSearchloading, setFilterSearchLoading] = useState(false);
    const [openDateDialog, setOpenDateDialog] = useState(false);
    // Alert
    const [showAlert, setShowAlert] = useState(false);

    useEffect(async () => {
        if (location.pathname == '/all-trips') {
            if (!await checkUserId()) return history.push('/login');
            fetchAllTrips();
            fetchAllTrucks();
        }
    }, [location.pathname])

    const fetchAllTrips = async () => {
        const body = { user_id: await checkUserId() }

        await axios.post(`${process.env.REACT_APP_BASEURL}/trip/all-trips`, body)
            .then(res => {
                if (res.data.success) {
                    dispatch({ type: FETCH_ALL_TRIPS, payload: res.data.data })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }

    const fetchAllTrucks = async () => {
        setFilterTrucksLoading(true);
        const body = { user_id: await checkUserId() }

        await axios.post(`${process.env.REACT_APP_BASEURL}/truck/all-trucks`, body)
            .then(res => {
                if (res.data.success) {
                    dispatch({ type: FETCH_ALL_TRUCKS, payload: res.data.data })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setFilterTrucksLoading(false);
            })
            .catch(err => {
                console.log(err);
                setFilterTrucksLoading(false);
            })
    }

    const onMyTripsCardClick = (data) => {
        setModalData(data);
        setOpenModal(true);
    }

    const onAddTrip = async () => {
        if (!await checkUserId()) return;

        if (tripInfo3_done) {
            history.push('/my-trips/review');
        } else if (tripInfo2_done) {
            history.push('/my-trips/3');
        } else if (tripInfo1_done) {
            history.push('/my-trips/2');
        } else {
            history.push('/my-trips/1');
        }
    }

    const onCardDelete = async () => {
        if (!deleteID) return enqueueSnackbar('Something Went Wrong, Try Again !!!!', { variant: 'error' })
        setDeleteLoading(true);

        const body = { trip_id: deleteID }
        await axios.put(`${process.env.REACT_APP_BASEURL}/trip/delete`, body)
            .then(res => {
                if (res.data.success) {
                    const updatedArr = [...allTrips].filter(u => u.trip_id !== deleteID);
                    dispatch({ type: FETCH_ALL_TRIPS, payload: updatedArr })
                    enqueueSnackbar('Trip Deleted Successfully', { variant: 'success' })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setDeleteID(null);
                setDeleteLoading(false);
            })
            .catch(err => {
                console.log(err);
                setDeleteID(null);
                setDeleteLoading(false);
            })
    }

    const onCardEditClick = (e, data) => {
        e.stopPropagation();
        setOpenEditModal(true);
        setEditModalData({ ...editmodalData, ...data });
        setBillOfLadingImagePreview(data?.bill_of_lading);
        setRateConfImagePreview(data?.rate_confirmation);
    }

    const onUpdateData = async () => {
        const body = { ...editmodalData }

        const { date_of_trip, profit, trip_number, from_city, from_state, to_city, to_state, total_loaded_miles, rate, comments } = body;
        if (!date_of_trip || !profit || !trip_number || !from_city || !from_state || !to_city || !to_state || !total_loaded_miles || !rate) return enqueueSnackbar('Please Fill All the Required Fields !!!', { variant: 'error' });

        const fData = new FormData();
        Object.keys(body)?.forEach(b => {
            if (b == 'bill_of_lading' || b == 'rate_confirmation') {
                return;
            } else {
                fData.append(b, body[b])
            }
        })
        fData.append('bill_of_lading', billOfLadingImage ? billOfLadingImage : billOfLadingImagePreview)
        fData.append('rate_confirmation', rateConfImage ? rateConfImage : rateConfImagePreview)

        setEditLoading(true);
        await axios.put(`${process.env.REACT_APP_BASEURL}/trip/update`, fData)
            .then(res => {
                if (res.data.success) {
                    const arr = [];
                    allTrips.forEach(a => {
                        if (a.trip_id == editmodalData.trip_id) {
                            arr.push({
                                ...editmodalData,
                                bill_of_lading: billOfLadingImage ? URL.createObjectURL(billOfLadingImage) : billOfLadingImagePreview,
                                rate_confirmation: rateConfImage ? URL.createObjectURL(rateConfImage) : rateConfImagePreview
                            })
                        } else {
                            arr.push(a)
                        }
                    })

                    dispatch({ type: FETCH_ALL_TRIPS, payload: arr })
                    enqueueSnackbar('Trip Updated Successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setOpenEditModal(false);
                return setEditLoading(false);
            })
            .catch(err => {
                console.log(err);
                return setEditLoading(false);
            })
    }

    const onFilter = async () => {
        // if (!filterTruckSelect && !filterFromCity && !filterToCity && !filterByDateCheckBox) return enqueueSnackbar('No Filters Added', { variant: 'error' })
        if (!filterTruckSelectCheckBox && !filterByCityCheckBox && !filterByDateCheckBox) return enqueueSnackbar('No Filters Added', { variant: 'error' })
        if (filterTruckSelectCheckBox && !filterTruckSelect) return enqueueSnackbar('Please Select a Truck', { variant: 'error' })
        if (filterByCityCheckBox && !filterFromCity && !filterToCity) return enqueueSnackbar('Please Select Cities', { variant: 'error' })

        const body = {
            user_id: await checkUserId(),
            truck_id: filterTruckSelect,
            date_of_trip: {
                date_of_trip_from: filterByDateCheckBox ? filterFromdateTime : null,
                date_of_trip_to: filterByDateCheckBox ? filterTodateTime : null
            },
            from_city: filterFromCity.toLocaleLowerCase(),
            to_city: filterToCity.toLocaleLowerCase()
        }

        if (filterTruckSelect && !filterFromCity && !filterToCity && !filterByDateCheckBox) {
            const arr = allTrips?.filter(a => a.truck_id == filterTruckSelect);
            setFilterTripsData(arr);
            setOpenFilterModal(false);
        } else {
            setFilterSearchLoading(true);
            await axios.post(`${process.env.REACT_APP_BASEURL}/trip/filter-trips`, body)
                .then(res => {
                    if (res.data.success) {
                        setFilterTripsData(res.data.data);
                    } else {
                        enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
                    }
                    setFilterSearchLoading(false);
                    setOpenFilterModal(false);
                })
                .catch(err => {
                    console.log(err);
                    setFilterSearchLoading(false);
                })
        }
    }

    const onRemoveFilter = () => {
        setFilterTripsData([]);
        setFilterTruckSelect('');
        setFilterFromCity('');
        setFilterToCity('');
        setFilterFromDateTime(moment(new Date).format("yyyy-MM-DD"));
        setFilterToDateTime(moment(new Date).format("yyyy-MM-DD"));
        setFilterByDateCheckBox(false);
    }

    const calcFilterTotal = () => {
        let initialValue = 0;
        filterTripsData.forEach(t => initialValue += parseFloat(t.profit));
        return initialValue;
    }

    const onAlertDismiss = (e) => {
        if (e.detail.role == 'confirm') {
            setShowAlert(false);
            onCardDelete();
        } else {
            setShowAlert(false);
            setDeleteID(null);
        }
    }

    const onAddImgToDialog = (e, heading) => {
        if (heading == 'lading') {
            setBillOfLadingImage(e.target.files[0]);
            setBillOfLadingImagePreview(URL?.createObjectURL(e.target.files[0]));
        } else {
            setRateConfImage(e.target.files[0]);
            setRateConfImagePreview(URL?.createObjectURL(e.target.files[0]));
        }
    }

    const onRemoveImgToDialog = (e, heading) => {
        if (heading == 'lading') {
            setBillOfLadingImage('');
            setBillOfLadingImagePreview('');
            setEditModalData({ ...editmodalData, bill_of_lading: '' })
        } else {
            setRateConfImage('');
            setRateConfImagePreview('');
            setEditModalData({ ...editmodalData, rate_confirmation: '' })
        }
    }

    const showTrips = filterTripsData?.length > 0 ? filterTripsData : allTrips;
    return (
        <CustomIonicWrapper title='My Trips' onBackClick={() => history.push('/home')}>
            <CustomAlert open={showAlert} header='Are You Sure ?' onDismiss={onAlertDismiss} message='On Confirm All Data Will Be Deleted' />

            <section className={`profile_section ${isPlatform('ios') ? 'profile_section_ios' : ''}`} >
                <div className='profile_container' style={{ overflow: loading ? 'hidden' : 'auto' }} >
                    {loading ? (
                        <>{new Array(10).fill('').map((a, i) => <StandardTruckCardsSkeleton key={i} />)}</>
                    ) : (
                        <>
                            {allTrips?.length > 0 ? (
                                <>
                                    {filterTripsData?.length > 0 && (
                                        <div className="profile_container_filterData">
                                            <div className='profile_container_filterData_header'>
                                                <h2>Filter Data</h2>
                                                <span onClick={onRemoveFilter}>
                                                    <IonIcon icon={closeOutline} />
                                                </span>
                                            </div>
                                            {filterTruckSelect && <h4>Truck Selected: <span>{allTrucks?.filter(t => t.truck_id == filterTruckSelect)?.[0]?.truck_no}</span></h4>}
                                            <h4>Total Trips: <span>{filterTripsData?.length}</span></h4>
                                            <h4>Total Profit: <span>{calcFilterTotal()}</span></h4>
                                            {filterFromCity && <h4>From City: <span>{filterFromCity}</span></h4>}
                                            {filterToCity && <h4>To City: <span>{filterToCity}</span></h4>}
                                            {filterByDateCheckBox && <h4>From Date: <span>{filterFromdateTime}</span></h4>}
                                            {filterByDateCheckBox && <h4>To Date: <span>{filterTodateTime}</span></h4>}
                                        </div>
                                    )}
                                    {showTrips?.map((a, i) => (
                                        <StandardTruckCards key={i} onClick={() => onMyTripsCardClick(a)} noImg
                                            delLoading={deleteLoading && deleteID === a.trip_id}
                                            onDel={(e) => { e.stopPropagation(); setDeleteID(a.trip_id); setShowAlert(true) }}
                                            onEdit={(e) => onCardEditClick(e, a)}
                                            className='myTrips_standardTruckCard'
                                        >
                                            <h3>Trip Number: <span>{a.trip_number}</span></h3>
                                            <p>{a.from_city}, {a.from_state} To {a.to_city}, {a.to_state}</p>
                                            <p>Date Of Trip : <span>{moment(a.date_of_trip).format('MMM DD, YYYY')}</span></p>
                                        </StandardTruckCards>
                                    ))}
                                </>
                            ) : (
                                <div style={{ height: '100%', display: 'flex' }}>
                                    <NoDataImage img={viewTrips} heading='No Trips Found' subHeading='Add Some Trips' />
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className="profile_StandardAndroidBtn_cont">
                    <StandardAndroidBtn iconLeft={addCircleOutline} label='Add Trip' onClick={onAddTrip} />
                </div>
                {allTrips?.length > 0 && (
                    <Fab size='medium' className='allTrucks_fab' onClick={() => setOpenFilterModal(true)} >
                        <IonIcon icon={filterOutline} style={{ color: '#fff', fontSize: 25 }} />
                    </Fab>
                )}
            </section>

            <InfoModal open={openModal} setOpen={setOpenModal} >
                <ProfileModalData heading='Trip Number' amt={modalData.trip_number} />
                <ProfileModalData heading='Profit' amt={modalData.profit} dollar />
                <ProfileModalData heading='Rate' amt={modalData.rate} dollar />
                <ProfileModalData heading='Date Of Trip' amt={moment(modalData.date_of_trip).format('MM/DD/YYYY')} />
                <ProfileModalData heading='From City' amt={modalData.from_city} />
                <ProfileModalData heading='From State' amt={modalData.from_state} />
                <ProfileModalData heading='To State' amt={modalData.to_state} />
                <ProfileModalData heading='To City' amt={modalData.to_city} />
                <ProfileModalData heading='Total Loaded Miles' amt={modalData.total_loaded_miles} />
                <ProfileModalData heading='Comments' amt={modalData.comments} />

                {modalData?.bill_of_lading && (
                    <div className='profile_truckModal_Imgcont'>
                        <h3>Bill Of Lading</h3>
                        <img src={modalData?.bill_of_lading} alt='Bill Of Lading' />
                    </div>
                )}

                {modalData?.rate_confirmation && (
                    <div className='profile_truckModal_Imgcont'>
                        <h3>Rate Confirmation</h3>
                        <img src={modalData?.rate_confirmation} alt='Rate Confirmation' />
                    </div>
                )}
            </InfoModal>

            <EditModal open={openEditModal} setOpen={setOpenEditModal} onUpdate={onUpdateData} loading={editloading}>
                <CustomMuiInputNormal lable='Trip Number' val={editmodalData.trip_number} name='trip_number' editValData={editmodalData} setVal={setEditModalData} mt={15} readOnly />
                <CustomMuiInput lable='Profit' val={editmodalData.profit} name='profit' editValData={editmodalData} setVal={setEditModalData} mt={15} startAdornment='$' />
                <CustomMuiInput lable='Rate' val={editmodalData.rate} name='rate' editValData={editmodalData} setVal={setEditModalData} mt={15} startAdornment='$' />

                <div onClick={() => setOpenDateDialog(true)} >
                    <CustomMuiInputNormal lable='Date Of Trip' val={moment(editmodalData.date_of_trip).format('MM/DD/YYYY')} name='date_of_trip'
                        editValData={editmodalData} setVal={setEditModalData} mt={15} readOnly
                    />
                </div>

                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <MobileDatePicker
                        label="Date Of Trip"
                        format='MM/DD/YYYY'
                        value={dayjs(editmodalData?.date_of_trip)}
                        onAccept={(val) => setEditModalData({ ...editmodalData, 'date_of_trip': moment(val?.$d).format('YYYY-MM-DD 00:00:00') })}
                        onClose={(val) => setOpenDateDialog(false)}
                        sx={{ display: 'none' }}
                        open={openDateDialog}
                    />
                </LocalizationProvider>

                <CustomMuiInputNormal lable='From City' val={editmodalData.from_city} name='from_city' editValData={editmodalData} setVal={setEditModalData} mt={15} />

                <FormControl fullWidth variant='standard' sx={{ marginTop: '10px' }} >
                    <InputLabel sx={{ color: '#000' }} >From State</InputLabel>
                    <Select value={editmodalData.from_state} label="From State" onChange={e => setEditModalData({ ...editmodalData, from_state: e.target.value })} >
                        {statesArr?.map((t, i) => <MenuItem key={i} value={t}>{t}</MenuItem>)}
                    </Select>
                </FormControl>

                <CustomMuiInputNormal lable='To City' val={editmodalData.to_city} name='to_city' editValData={editmodalData} setVal={setEditModalData} mt={15} />

                <FormControl fullWidth variant='standard' sx={{ marginTop: '10px' }} >
                    <InputLabel sx={{ color: '#000' }} >To State</InputLabel>
                    <Select value={editmodalData.to_state} label="From State" onChange={e => setEditModalData({ ...editmodalData, to_state: e.target.value })} >
                        {statesArr?.map((t, i) => <MenuItem key={i} value={t}>{t}</MenuItem>)}
                    </Select>
                </FormControl>

                <CustomMuiInputNormal lable='Total Loaded Miles' val={editmodalData.total_loaded_miles} name='total_loaded_miles' editValData={editmodalData} setVal={setEditModalData} mt={15} />
                <CustomMuiInputNormal lable='Comments' val={editmodalData.comments} name='comments' editValData={editmodalData} setVal={setEditModalData} mt={15} />

                <Button variant="contained" component="label" disableElevation fullWidth className='myTrips_uploadDialog_btn' >
                    Bill Of Lading
                    <input hidden accept="image/*" type="file" onChange={(e) => onAddImgToDialog(e, 'lading')} />
                </Button>
                {billOfLadingImagePreview && (
                    <div className='myTrips_previewImg' style={{ background: `url(${billOfLadingImagePreview}) no-repeat center/contain` }}>
                        <IonIcon icon={trashOutline} style={{ color: '#98002e' }} onClick={(e) => onRemoveImgToDialog(e, 'lading')} />
                    </div>
                )}

                <Button variant="contained" component="label" disableElevation fullWidth className='viewDocs_uploadDialog_btn' >
                    Rate Confirmation
                    <input hidden accept="image/*" type="file" onChange={(e) => onAddImgToDialog(e, 'rateConf')} />
                </Button>
                {rateConfImagePreview && (
                    <div className='myTrips_previewImg' style={{ background: `url(${rateConfImagePreview}) no-repeat center/contain` }}>
                        <IonIcon icon={trashOutline} style={{ color: '#98002e' }} onClick={(e) => onRemoveImgToDialog(e, 'rateConf')} />
                    </div>
                )}
            </EditModal>

            {/* FILTER MODAL */}
            {allTrips?.length > 0 && (
                <EditModal open={openFilterModal} setOpen={setOpenFilterModal} className='myTrips_filterModal' onUpdate={onFilter} editModalText='Filter' loading={filterSearchloading} >

                    <StandardCheckBox checked={filterTruckSelectCheckBox} setChecked={setFilterTruckSelectCheckBox} label="Search By Trucks" mt='0px' />
                    {filterTruckSelectCheckBox && (
                        <FormControl fullWidth >
                            <InputLabel sx={{ color: '#000' }} >Select Truck</InputLabel>
                            <Select value={filterTruckSelect} label="Select Truck" onChange={e => setFilterTruckSelect(e.target.value)} disabled={filterTrucksLoading} >
                                <MenuItem value='' >All Trucks</MenuItem>
                                {allTrucks?.map((a) => <MenuItem value={a.truck_id} key={a.truck_id} >{a.truck_no}</MenuItem>)}
                            </Select>
                        </FormControl>
                    )}

                    <StandardCheckBox checked={filterByCityCheckBox} setChecked={setFilterByCityCheckBox} label="Search By Cities" />
                    {filterByCityCheckBox && (
                        <>
                            <CustomMuiInputNormal lable='From City' val={filterFromCity} setVal={setFilterFromCity} variant='outlined' mt={1} />
                            <CustomMuiInputNormal lable='To City' val={filterToCity} setVal={setFilterToCity} variant='outlined' mt={20} />
                        </>
                    )}

                    <StandardCheckBox checked={filterByDateCheckBox} setChecked={setFilterByDateCheckBox} label="Search By Date" />
                    {filterByDateCheckBox && (
                        <div className='myTrips_date_cont' >
                            <div className="myTrips_date">
                                <h5>From Date</h5>
                                <StandardAndroidBtn label={moment(filterFromdateTime).format("DD-MM-YYYY")} onClick={() => setShowFromDateTime(!showFromDateTime)} />
                                {showFromDateTime && (
                                    <IonDatetime presentation="date" mode='md' showDefaultButtons={true}
                                        cancelText='Today' onIonCancel={() => setFilterFromDateTime(moment(new Date).format("yyyy-MM-DD"))}
                                        value={filterFromdateTime} onIonChange={e => { setFilterFromDateTime(e.detail.value); setShowFromDateTime(false) }}
                                    />
                                )}
                            </div>

                            <div className="myTrips_date">
                                <h5>To Date</h5>
                                <StandardAndroidBtn label={moment(filterTodateTime).format("DD-MM-YYYY")} onClick={() => setShowToDateTime(!showToDateTime)} />
                                {showToDateTime && (
                                    <IonDatetime presentation="date" mode='md' showDefaultButtons={true}
                                        cancelText='Today' onIonCancel={() => setFilterToDateTime(moment(new Date).format("yyyy-MM-DD"))}
                                        value={filterTodateTime} onIonChange={e => { setFilterToDateTime(e.detail.value); setShowToDateTime(false) }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </EditModal>
            )}
        </CustomIonicWrapper>
    )
}

export default MyTrips

const ProfileModalData = ({ heading, amt, dollar }) => (
    <div className='profile_truckModal_data'>
        <h3>{heading}</h3>
        <p>{dollar ? '$' : ''} {amt}</p>
    </div>
)

const StandardCheckBox = ({ checked, setChecked, label, mt }) => (
    <FormControlLabel sx={{ display: 'block', marginTop: mt || 2 }}
        control={<Checkbox checked={checked} onChange={e => setChecked(e.target.checked)} className='myTrips_filterModal_checkBox' />}
        label={label}
    />
)

const statesArr = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA",
    "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN",
    "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
]