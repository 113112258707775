import '../TripDocuments/TripDocuments.css';
import './MyDocuments.css'
import { useHistory } from 'react-router'
import { ListItem, ListItemButton } from '@mui/material';
// COMPONENTS
import CustomIonicWrapper from '../../../components/CustomIonicWrapper/CustomIonicWrapper'

function MyDocuments() {
    const history = useHistory();

    const onDocClick = (heading) => {
        const body = { heading }
        history.push('/view-documents', { body })
    }

    return (
        <CustomIonicWrapper title='My Documents' onBackClick={() => history.push('/all-documents')}>
            <div className="myDocs_container">
                <section className="myDocs_section myDocuments_section">
                    {docsListArr?.map((d, i) => (
                        <MyDocumentsList heading={d.heading} key={i} onClick={() => onDocClick(d.heading)} />
                    ))}
                </section>
            </div>
        </CustomIonicWrapper>
    )
}

export default MyDocuments

const MyDocumentsList = ({ heading, onClick }) => (
    <ListItem disablePadding dense className='myDocumentsList_cont' onClick={onClick} >
        <ListItemButton>
            <p>{heading}</p>
            <span>{'>'}</span>
        </ListItemButton>
    </ListItem>
)

const docsListArr = [
    { heading: 'Company Documents' },
    { heading: 'Broker Package' },
    { heading: 'Truck / Trailer Documents' },
    { heading: 'Fuel Receipts' },
    { heading: 'Expense Receipts' },
    { heading: 'IFTA' },
    { heading: 'Misc' }
]