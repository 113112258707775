import { useState } from 'react';
import '../TruckInfo1/TruckInfo1.css';
import './ReviewInputs.css';
import { useHistory } from 'react-router';
import axios from 'axios';
import { checkUserId } from '../../../redux/actions/exportFunctions';
import { useSnackbar } from 'notistack';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RESET_TRUCK_INFO } from '../../../redux/type';
// Components
import CustomIonicWrapper from '../../../components/CustomIonicWrapper/CustomIonicWrapper';
import InfoPageTempelate from '../../../components/InfoPageTempelate/InfoPageTempelate';

function ReviewInputs() {
    const history = useHistory();
    const dispatch = useDispatch();
    const truck = useSelector(s => s.truck.truckData);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false)

    const onConfirm = async () => {
        setLoading(true);
        const body = {
            ...truck,
            user_id: await checkUserId(),
            dispatch_fee_type: truck.dispatch_fee_type === false ? '$' : '%',
            carrier_authority_type: truck.carrier_authority_type === false ? '$' : '%'
        }

        await axios.post(`${process.env.REACT_APP_BASEURL}/truck/add`, body)
            .then(res => {
                if (res.data.success) {
                    dispatch({ type: RESET_TRUCK_INFO });
                    enqueueSnackbar('Truck Added Successfully', { variant: 'success' });
                    history.push('/all-trucks');
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                return setLoading(false);
            })
            .catch(err => {
                console.log(err);
                return setLoading(false);
            })
    }

    return (
        <CustomIonicWrapper title='Truck Details' onBackClick={() => history.push('/all-trucks')}>
            <div className='truckInfo_cont reviewInputs_cont' >
                <InfoPageTempelate btn1Label='Back' btn1Click={() => history.push('/truck-information/3')}
                    btn2Label='Save' btn2Click={onConfirm} loading={loading} pageTempelateHeader='Details are Per Month'
                >
                    {/* <TruckRevieData heading='Truck Number' amt={truck.truck_no} /> */}
                    <div className="truckInfo_review_data_header">
                        <p>Truck Number :</p>
                        <h3>{truck.truck_no}</h3>
                    </div>
                    <TruckRevieData heading='Insurance' amt={truck.insurance} dollar />
                    <TruckRevieData heading='Bank Loan' amt={truck.loan_amount_to_bank} dollar />
                    <TruckRevieData heading='Tolls' amt={truck.tolls} dollar />
                    <TruckRevieData heading='Pre Maintenance (PM)' amt={truck.pm} dollar />
                    <TruckRevieData heading='County Tax' amt={truck.county_tax} dollar />
                    <TruckRevieData heading='Apportion Plate Cost' amt={truck.apportion_plate_cost} dollar />
                    <TruckRevieData heading='Admin Cost' amt={truck.admin_cost} dollar />
                    {truck.factoring_rate && <TruckRevieData heading='Factoring Rate %' amt={`${truck.factoring_rate} %`} />}
                    {truck.dispatch_fee && <TruckRevieData heading='Dispatch Fee' amt={`${truck.dispatch_fee_type === false ? '$' : ''} ${truck.dispatch_fee} ${truck.dispatch_fee_type === true ? '%' : ''}`} />}
                    <TruckRevieData heading='Miscellaneous' amt={truck.misc} dollar />
                    {truck.carrier_authority && <TruckRevieData heading='Carrier Authority' amt={`${truck.carrier_authority_type === false ? '$' : ''} ${truck.carrier_authority} ${truck.carrier_authority_type === true ? '%' : ''}`} />}
                    <TruckRevieData heading='Approx Miles Per Year' amt={truck.approx_miles_per_year} />
                    <TruckRevieData heading='Average Miles Per Gallon' amt={truck.fuel_mileage} />
                    {truck.ifta_ucr && <TruckRevieData heading='IFTA / UCR / 2290' amt={truck.ifta_ucr} dollar />}
                </InfoPageTempelate>
            </div>
        </CustomIonicWrapper>
    )
}

export default ReviewInputs

const TruckRevieData = ({ heading, amt, dollar }) => (
    <div className='truckInfo_review_data'>
        <h3>{heading}</h3>
        <p>{dollar ? '$' : ''} {amt}</p>
    </div>
)