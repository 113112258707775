import { ADD_TRUCK_INFO_1, ADD_TRUCK_INFO_2, ADD_TRUCK_INFO_3, FETCH_ALL_TRUCKS, RESET_TRUCK_INFO } from "../type";

const initialState = {
    truckData: {
        truck_no: '',
        apportion_plate_cost: '',
        insurance: '',
        loan_amount_to_bank: '',
        county_tax: '',
        tolls: '',
        admin_cost: '',
        approx_miles_per_year: '',
        pm: '',
        misc: '',
        fuel_mileage: '',
        factoring_rate: '',
        dispatch_fee: '',
        dispatch_fee_type: false,
        carrier_authority: '',
        carrier_authority_type: true,
        ifta_ucr: ''
    },
    truckInfo1_done: false,
    truckInfo2_done: false,
    truckInfo3_done: false,
    getAllTrucks: [],
}

export const truckDataReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case ADD_TRUCK_INFO_1:
            return state = {
                ...state,
                truckData: {
                    ...state.truckData,
                    ...actions.truckPayloadData
                },
                truckInfo1_done: actions.truckInfoDone1
            }

        case ADD_TRUCK_INFO_2:
            return state = {
                ...state,
                truckData: {
                    ...state.truckData,
                    ...actions.truckPayloadData
                },
                truckInfo2_done: actions.truckInfoDone2
            }

        case ADD_TRUCK_INFO_3:
            return state = {
                ...state,
                truckData: {
                    ...state.truckData,
                    ...actions.truckPayloadData
                },
                truckInfo3_done: actions.truckInfoDone3
            }

        case RESET_TRUCK_INFO:
            return state = {
                ...state,
                truckData: {
                    truck_no: '',
                    apportion_plate_cost: '',
                    insurance: '',
                    loan_amount_to_bank: '',
                    county_tax: '',
                    tolls: '',
                    admin_cost: '',
                    approx_miles_per_year: '',
                    pm: '',
                    misc: '',
                    fuel_mileage: '',
                    factoring_rate: '',
                    dispatch_fee: '',
                    dispatch_fee_type: false,
                    carrier_authority: '',
                    carrier_authority_type: true,
                    ifta_ucr: ''
                },
                truckInfo1_done: false,
                truckInfo2_done: false,
                truckInfo3_done: false,
            }

        case FETCH_ALL_TRUCKS:
            return state = {
                ...state,
                getAllTrucks: actions.payload
            }

        default:
            return state;
    }
}