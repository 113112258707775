import CustomIonicWrapper from "../../components/CustomIonicWrapper/CustomIonicWrapper";
import TermsConditionsData from '../../components/TermsConditionsData/TermsConditionsData';
import { useHistory } from 'react-router';

function TermsConditions() {
    const history = useHistory();

    return (
        <CustomIonicWrapper title='Terms & Conditions' onBackClick={() => history.goBack()}>
            <TermsConditionsData />
        </CustomIonicWrapper>
    )
}

export default TermsConditions