import { useEffect, useState } from 'react';
import '../../TruckInformation/TruckInfo1/TruckInfo1.css';
import './MyTripInfo1.css'
import { IonDatetime } from '@ionic/react'
import { useHistory, useLocation } from 'react-router';
import axios from 'axios';
import moment from 'moment'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { checkUserId } from '../../../redux/actions/exportFunctions';
import { useSnackbar } from 'notistack';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TRIP_INFO_1, FETCH_ALL_TRUCKS } from '../../../redux/type';
// Components
import CustomIonicWrapper from '../../../components/CustomIonicWrapper/CustomIonicWrapper';
import CustomMuiInput from '../../../components/CustomMuiInput/CustomMuiInput';
// ICONs & SVGs
import InfoPageTempelate from '../../../components/InfoPageTempelate/InfoPageTempelate';
import StandardAndroidBtn from '../../../components/StandardAndroidBtn/StandardAndroidBtn';

function MyTripInfo1() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const allTrucks = useSelector(s => s.truck.getAllTrucks);
    const trip = useSelector(s => s.trip);
    const { enqueueSnackbar } = useSnackbar();

    const [truckId, setTruckId] = useState('');
    const [profit, setProfit] = useState('');
    const [tripNumber, setTripNumber] = useState('');
    const [rate, setRate] = useState('');
    const [dateTime, setDateTime] = useState(moment(new Date).format("yyyy-MM-DDTHH:mm:ssZ"));
    const [truckLoading, setTruckLoading] = useState(false);
    const [showDateTime, setShowDateTime] = useState(false);

    useEffect(() => {
        if (pathname == '/my-trips/1') {
            fetchAllTrucks();

            setTruckId(trip.tripData.truck_id);
            setProfit(trip.tripData.profit);
            setTripNumber(trip.tripData.trip_number);
            setRate(trip.tripData.rate);
            setDateTime(trip.tripData.date_of_trip || moment(new Date).format("yyyy-MM-DDTHH:mm:ssZ"));
        }
    }, [pathname])

    const fetchAllTrucks = async () => {
        if (!await checkUserId()) return;

        setTruckLoading(true);
        const body = { user_id: await checkUserId() }

        await axios.post(`${process.env.REACT_APP_BASEURL}/truck/all-trucks`, body)
            .then(res => {
                if (res.data.success) {
                    dispatch({ type: FETCH_ALL_TRUCKS, payload: res.data.data })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setTruckLoading(false);
            })
            .catch(err => {
                console.log(err);
                setTruckLoading(false);
            })
    }

    const onNextTripInfo1 = async () => {
        if (!truckId || !profit || !tripNumber || !dateTime || !rate) return enqueueSnackbar('Please Fill All The Details !!!', { variant: 'error' })

        dispatch({
            type: ADD_TRIP_INFO_1,
            tripData_1: {
                truck_id: truckId, date_of_trip: dateTime, profit, trip_number: tripNumber, rate,
                user_id: await checkUserId()
            },
            tripInfo1_done: true
        })

        return history.push('/my-trips/2');
    }

    return (
        <CustomIonicWrapper title='Trip Information' onBackClick={() => history.push('/all-trips')}>
            <div className='truckInfo_cont' >
                <div className='truckInfo_stepImg' ></div>

                <InfoPageTempelate btn1Label='Home' btn1Click={() => history.push('/all-trips')} btn2Click={onNextTripInfo1} >
                    <FormControl fullWidth disabled={truckLoading} variant='standard' >
                        <InputLabel sx={{ color: '#000' }} >Select Truck</InputLabel>
                        <Select value={truckId} label="Select Truck" onChange={e => setTruckId(e.target.value)} >
                            {allTrucks?.map(t => <MenuItem key={t.truck_id} value={t.truck_id}>{t.truck_no}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <CustomMuiInput lable='Trip Number' val={tripNumber} setVal={setTripNumber} shrink placeholder='Eg: 986' />
                    <CustomMuiInput lable='Profit' val={profit} setVal={setProfit} type='number' startAdornment='$' />
                    <CustomMuiInput lable='Rate' val={rate} setVal={setRate} type='number' startAdornment='$' />

                    {/* DATE TIME PICKER */}
                    <div className="myTripInfo_date">
                        <StandardAndroidBtn label={moment(dateTime).format("DD-MM-YYYY/hh:mm a")} onClick={() => setShowDateTime(!showDateTime)} />
                        {showDateTime && (
                            <IonDatetime presentation="time-date" mode='md' showDefaultButtons={true}
                                cancelText='Today' onIonCancel={() => setDateTime(moment(new Date).format("yyyy-MM-DDTHH:mm:ssZ"))}
                                value={dateTime} onIonChange={e => { setDateTime(e.detail.value); setShowDateTime(false) }}
                            />
                        )}
                    </div>
                </InfoPageTempelate>
            </div>
        </CustomIonicWrapper>
    )
}

export default MyTripInfo1