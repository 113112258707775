import './ChangePassword.css'
import "../SignUp/Signup.css";
import { useState } from 'react';
import { IonImg } from '@ionic/react'
import { useHistory, useParams } from 'react-router';
import axios from 'axios';
// Components
import CustomIonicWrapper from '../../components/CustomIonicWrapper/CustomIonicWrapper';
import StandardAndroidBtn from '../../components/StandardAndroidBtn/StandardAndroidBtn';
import CustomMuiInputNormal from '../../components/CustomMuiInput/CustomMuiInputNormal';
// IMGS & SVGS
import SignUplogo from '../../imgs/signUp-logo.png'
import { useSnackbar } from 'notistack';

function ChangePassword() {
    const history = useHistory();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const onChangePass = async () => {
        if (!params.token) return enqueueSnackbar('Something Went Wrong Please Try Again !!!', { variant: 'error' })
        if (!password || !confirmpassword) return enqueueSnackbar('Please Fill all the Details !!!', { variant: 'error' })
        if (password !== confirmpassword) return enqueueSnackbar("Passwords doesn't match !!!", { variant: 'error' })

        setLoading(true);

        const body = { token: params.token, password }
        await axios.post(`${process.env.REACT_APP_BASEURL}/user/reset-password`, body)
            .then(res => {
                if (res.data.success) {
                    enqueueSnackbar('Password Changed Successfully !!!', { variant: 'success' })
                    history.push('/login');
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                return setLoading(false);
            })
            .catch(err => {
                console.log(err);
                return setLoading(false);
            })
    }

    return (
        <CustomIonicWrapper title='Change Password'>
            <IonImg src={SignUplogo} alt="My Truck Profit" className="signup_logo" />
            <div className="changepass_cont">
                <div className="changepass_data_cont">
                    <CustomMuiInputNormal lable='New Password' val={password} setVal={setPassword} type='password' />
                    <CustomMuiInputNormal lable='Confirm Password' val={confirmpassword} setVal={setConfirmPassword} type='password' />

                    <p>The password must be at least 8 characters</p>

                    <StandardAndroidBtn label='Change Password' onClick={onChangePass} loading={loading} />
                </div>
            </div>
        </CustomIonicWrapper>
    )
}

export default ChangePassword