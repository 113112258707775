import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components
import Menu from '../components/Menu';
// Pages
import Signup from '../pages/SignUp/Signup';
import AllTrucks from '../pages/TruckInformation/AllTrucks/AllTrucks';
import ChangePassword from '../pages/ChangePassword/ChangePassword';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Login from '../pages/Login/Login';
import HomePage from '../pages/HomePage/HomePage';
import LandingPage from '../pages/LandingPage/LandingPage';
import ReviewInputs from '../pages/TruckInformation/ReviewInputs/ReviewInputs';
import MyAccount from '../pages/MyAccount/MyAccount';
import CalculateTripProfilt from './CalculateTripProfilt/CalculateTripProfilt';
import Services from './Services/Services';
import Subscription from './Subscription/Subscription';
import Notifications from './Notifications/Notifications';
import TermsConditions from './Terms&Conditions/Terms&Conditions';
// Truck Information
import TruckInfo1 from '../pages/TruckInformation/TruckInfo1/TruckInfo1';
import TruckInfo2 from '../pages/TruckInformation/TruckInfo2/TruckInfo2';
import TruckInfo3 from '../pages/TruckInformation/TruckInfo3/TruckInfo3';
import PayYourDriver from '../pages/PayYourDriver/PayYourDriver';
// Trip Information
import MyTrips from '../pages/MyTripInfo/MyTrips/MyTrips';
import MyTripInfo1 from '../pages/MyTripInfo/MyTripInfo1/MyTripInfo1';
import MyTripInfo2 from '../pages/MyTripInfo/MyTripInfo2/MyTripInfo2';
import MyTripInfo3 from '../pages/MyTripInfo/MyTripInfo3/MyTripInfo3';
import ReviewMyTripData from '../pages/MyTripInfo/ReviewMyTripData/ReviewMyTripData';
// Documents
import AllDocuments from './MyDocuments/AllDocuments/AllDocuments';
import ViewDocument from './MyDocuments/ViewDocument/ViewDocument';
import MyDocuments from './MyDocuments/MyDocuments/MyDocuments';
import TripDocuments from './MyDocuments/TripDocuments/TripDocuments';
// ICONs & SVGs
import customSplash from '../imgs/custom-splash.svg'

function Layout() {
    const userLoggedIn = useSelector(s => s.user.userLoggedIn);
    // console.log(userLoggedIn);

    if (userLoggedIn === undefined) {
        return <div style={{ background: `#00235F url(${customSplash}) no-repeat center/contain`, height: '100vh', width: '100%', overflow: 'hidden' }} />
    }

    return (
        <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
                {!userLoggedIn ? (
                    <>
                        {/* Login & SignUp Pages */}
                        <Route path="/" exact={true} component={LandingPage} />
                        <Route path="/home" exact={true} render={() => <Redirect to='/' />} />
                        <Route path="/login" exact={true} component={Login} />
                        <Route path="/signup" exact={true} component={Signup} />
                        <Route path="/change-password/:token" exact={true} component={ChangePassword} />
                        <Route path="/forgot-password" exact={true} component={ForgotPassword} />
                        <Route path="/t&c" exact={true} component={TermsConditions} />
                    </>
                ) : (
                    <>
                        <Route path="/" exact={true} render={() => <Redirect to='/home' />} />
                        <Route path="/home" exact={true} component={HomePage} />
                        <Route path="/services" exact={true} component={Services} />
                        <Route path="/notifications" exact={true} component={Notifications} />
                        {/* My Account */}
                        <Route path="/account" exact={true} component={MyAccount} />
                        <Route path="/subscription" exact={true} component={Subscription} />
                        {/* Truck Information */}
                        <Route path="/all-trucks" exact={true} component={AllTrucks} />
                        <Route path="/truck-information" exact={true} component={TruckInfo1} />
                        <Route path="/truck-information/2" exact={true} component={TruckInfo2} />
                        <Route path="/truck-information/3" exact={true} component={TruckInfo3} />
                        <Route path="/truck-information/review" exact={true} component={ReviewInputs} />
                        {/* Trip Information */}
                        <Route path="/all-trips" exact={true} component={MyTrips} />
                        <Route path="/my-trips/1" exact={true} component={MyTripInfo1} />
                        <Route path="/my-trips/2" exact={true} component={MyTripInfo2} />
                        <Route path="/my-trips/3" exact={true} component={MyTripInfo3} />
                        <Route path="/my-trips/review" exact={true} component={ReviewMyTripData} />
                        {/* Calculate Trip Profit */}
                        <Route path="/pay-driver" exact={true} component={PayYourDriver} />
                        <Route path="/calculate-profit" exact={true} component={CalculateTripProfilt} />
                        {/* My Documents */}
                        <Route path="/all-documents" exact={true} component={AllDocuments} />
                        <Route path="/my-documents" exact={true} component={MyDocuments} />
                        <Route path="/trip-documents" exact={true} component={TripDocuments} />
                        <Route path="/view-documents" exact={true} component={ViewDocument} />
                        <Route path="/t&c" exact={true} component={TermsConditions} />
                    </>
                )}
            </IonRouterOutlet>
        </IonSplitPane>
    )
}

export default Layout

// const PrivateRoute = ({ redirect, children }) => {
//     const userLoggedIn = useSelector(s => s.user.userLoggedIn);
//     console.log(userLoggedIn);

//     return userLoggedIn ? children : <Redirect to={redirect || '/'} />;
// };