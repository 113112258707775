import { useEffect, useState } from 'react';
import './TruckInfo1.css';
import { useHistory, useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TRUCK_INFO_1 } from '../../../redux/type';
// Components
import CustomIonicWrapper from '../../../components/CustomIonicWrapper/CustomIonicWrapper';
import CustomMuiInput from '../../../components/CustomMuiInput/CustomMuiInput';
// ICONs & SVGs
import InfoPageTempelate from '../../../components/InfoPageTempelate/InfoPageTempelate';

function TruckInfo1() {
    const history = useHistory();
    const dipatch = useDispatch();
    const { pathname } = useLocation();
    const truck = useSelector(s => s.truck);
    const { enqueueSnackbar } = useSnackbar();

    const [truckNo, setTruckNo] = useState('');
    const [insurance, setInsurance] = useState('');
    const [loanToBank, setLoanToBank] = useState('');
    const [tolls, setTolls] = useState('');
    const [PM, setPM] = useState('');

    useEffect(() => {
        if (pathname == '/truck-information') {
            setTruckNo(truck.truckData.truck_no);
            setInsurance(truck.truckData.insurance);
            setLoanToBank(truck.truckData.loan_amount_to_bank);
            setTolls(truck.truckData.tolls)
            setPM(truck.truckData.pm)
        }
    }, [pathname])

    const onNextTruckInfo1 = () => {
        if (!truckNo || !insurance || !loanToBank || !tolls || !PM) return enqueueSnackbar('Please Enter All The Fields... Enter 0 IF Not Applicable !!!', { variant: 'error' })

        dipatch({
            type: ADD_TRUCK_INFO_1,
            truckPayloadData: { truck_no: truckNo, insurance, loan_amount_to_bank: loanToBank, tolls, pm: PM },
            truckInfoDone1: true
        })

        return history.push('/truck-information/2');
    }

    return (
        <CustomIonicWrapper title='Truck Information' onBackClick={() => history.push('/all-trucks')}>
            <div className='truckInfo_cont' >
                <div className='truckInfo_stepImg' ></div>

                <InfoPageTempelate btn1Label='Home' btn1Click={() => history.push('/all-trucks')} btn2Click={onNextTruckInfo1} pageTempelateHeader='Enter Details Per Month' >
                    <CustomMuiInput lable='Truck Number' val={truckNo} setVal={setTruckNo} autofocus shrink placeholder='Eg: US-254' />
                    <CustomMuiInput lable='Insurance' val={insurance} setVal={setInsurance} type='number' startAdornment='$' />
                    <CustomMuiInput lable='Bank Loan' val={loanToBank} setVal={setLoanToBank} type='number' startAdornment='$' />
                    <CustomMuiInput lable='Preventive Maintainence (PM)' val={PM} setVal={setPM} type='number' startAdornment='$' />
                    <CustomMuiInput lable='Tolls' val={tolls} setVal={setTolls} autofocus type='number' startAdornment='$' />
                </InfoPageTempelate>
            </div>
        </CustomIonicWrapper>
    )
}

export default TruckInfo1