import "./Signup.css";
import { Children, useState } from "react";
import { IonImg } from "@ionic/react";
import { useHistory } from "react-router";
import { Preferences } from '@capacitor/preferences';
import { useSnackbar } from "notistack";
import { Checkbox } from "@mui/material";
import axios from 'axios'
// Components
import TermsConditionsData from "../../components/TermsConditionsData/TermsConditionsData";
import CustomIonicWrapper from "../../components/CustomIonicWrapper/CustomIonicWrapper";
import StandardAndroidBtn from "../../components/StandardAndroidBtn/StandardAndroidBtn";
import CustomMuiInputNormal from "../../components/CustomMuiInput/CustomMuiInputNormal";
import EditModal from "../../components/EditModal/EditModal";
// IMGS & SVGS
import { logoApple, logoGoogle } from "ionicons/icons";
import SignUplogo from '../../imgs/signUp-logo.png'
// REDUX
import { useDispatch } from "react-redux";
import { ADD_USER, FISRT_USER_LOGIN_MODAL_UPDATE, UPDATE_USER_LOGIN } from "../../redux/type";

const Signup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [tcChecked, setTcChecked] = useState(true);
  const [loading, setLoading] = useState(false);

  const [tcModalOpen, setTcModalOpen] = useState(false);

  const onRegisterClick = async (e) => {
    e.preventDefault();
    if (!tcChecked) return enqueueSnackbar('Please Accept to the "Terms & Conditions" and "Privacy Policy"', { variant: 'error' });
    if (!firstName, !lastName, !number, !email, !password) return enqueueSnackbar('Please fill all the details', { variant: 'error' })
    if (firstName?.length <= 2) return enqueueSnackbar('Minimum 2 Letters Required', { variant: 'error' })
    if (lastName?.length <= 2) return enqueueSnackbar('Minimum 2 Letters Required', { variant: 'error' })
    if (password.length <= 5) return enqueueSnackbar('Password Must Be Atleast 5 Characters', { variant: 'error' })
    if (password !== confirmpassword) return enqueueSnackbar('Passwords do not match', { variant: 'error' })

    setLoading(true);
    const body = { first_name: firstName, middle_name: middleName, last_name: lastName, mobile_no: number, email, password }

    await axios.post(`${process.env.REACT_APP_BASEURL}/user/signup`, body)
      .then(res => {
        if (res.data.success) {
          setData(res.data.data[0]);

          dispatch({ type: ADD_USER, payload: res.data.data[0] });
          dispatch({ type: UPDATE_USER_LOGIN, payload: true });
          if (res.data.newUser) dispatch({ type: FISRT_USER_LOGIN_MODAL_UPDATE, payload: true });
          history.push('/home');
          reset();
          enqueueSnackbar('Registration Successfull', { variant: 'success' })
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error', style: { whiteSpace: 'pre-line' }, autoHideDuration: 7000 })
        }
        return setLoading(false);
      })
      .catch(err => {
        console.log(err);
        return setLoading(false);
      })
  }

  const reset = () => {
    setFirstName('');
    setMiddleName('')
    setLastName('');
    setNumber('');
    setEmail('');
    setPassword('');
    setConfirmPassword('')
  }

  const setData = async (data) => await Preferences.set({ key: 'truckola_users', value: JSON.stringify(data) });

  return (
    <CustomIonicWrapper title='Sign Up' onBackClick={() => history.goBack()}>
      <IonImg src={SignUplogo} alt="My Truck Profit" className="signup_logo" />
      <div className="signup_cont" >
        <div className="signup_dataCont" >
          <CustomMuiInputNormal lable='First Name' val={firstName} setVal={setFirstName} autofocus />
          <CustomMuiInputNormal lable='Middle Name' val={middleName} setVal={setMiddleName} />
          <CustomMuiInputNormal lable='Last Name' val={lastName} setVal={setLastName} />
          <CustomMuiInputNormal lable='Mobile Number' val={number} setVal={setNumber} type='number' />
          <CustomMuiInputNormal lable='Email ID' val={email} setVal={setEmail} type='email' />
          <CustomMuiInputNormal lable='Password' val={password} setVal={setPassword} type='password' />
          <CustomMuiInputNormal lable='Confirm Password' val={confirmpassword} setVal={setConfirmPassword} type='password' />

          <div className="signup_checkbox_cont">
            <Checkbox checked={tcChecked} size='small' onChange={e => setTcChecked(e.target.checked)} />
            <p onClick={() => setTcModalOpen(true)}>
              By Checking you Agree to the "Terms & Conditions" and "Privacy Policy"
            </p>
          </div>

          <StandardAndroidBtn label='Register' onClick={onRegisterClick} loading={loading} disabled={!tcChecked} />

          <EditModal open={tcModalOpen} setOpen={setTcModalOpen} onUpdate={() => setTcModalOpen(false)}
            loading={false} hideUpdateBtn
          >
            <TermsConditionsData />
          </EditModal>
        </div>
      </div>
    </CustomIonicWrapper>
  );
};

export default Signup;
