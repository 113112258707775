import { useEffect, useState } from 'react';
import './Notifications.css';
import { useHistory } from 'react-router';
import { IonIcon, IonRefresher, IonRefresherContent, isPlatform } from '@ionic/react';
import { checkUserId } from '../../redux/actions/exportFunctions';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_ALL_NOTIFICATIONS, NOTIFICATIONS_UPDATE_LOADING } from '../../redux/type';
// Components
import CustomIonicWrapper from '../../components/CustomIonicWrapper/CustomIonicWrapper';
import StandardHeader from '../../components/StandardHeader/StandardHeader';
// IMGs & SVGs
import noNotificatiosGif from '../../imgs/no-notifications.gif'
import { chevronDown, chevronUp } from 'ionicons/icons';

function Notifications() {
    const history = useHistory();
    const dispatch = useDispatch();
    const notifications = useSelector(s => s.notification);
    const { enqueueSnackbar } = useSnackbar();

    const [selectedNotification, setSelectedNotification] = useState(null)

    useEffect(() => {
        if (isPlatform('desktop')) getAllNotifications();
    }, [])

    const getAllNotifications = async (e) => {
        if (!await checkUserId()) return;

        dispatch({ type: NOTIFICATIONS_UPDATE_LOADING, payload: true })

        const body = { user_id: await checkUserId() }
        await axios.post(`${process.env.REACT_APP_BASEURL}/notification/get-all`, body)
            .then(res => {
                if (res.data.success) {
                    dispatch({ type: FETCH_ALL_NOTIFICATIONS, payload: res.data.data });
                    dispatch({ type: NOTIFICATIONS_UPDATE_LOADING, payload: false });
                    e?.detail?.complete();
                }
            })
            .catch(err => {
                console.log(err);
                e?.detail?.complete();
                dispatch({ type: NOTIFICATIONS_UPDATE_LOADING, payload: false })
            })
    }

    const onNotificationClick = async (id, readStatus) => {
        if (selectedNotification == id) {
            setSelectedNotification(null);
        } else {
            setSelectedNotification(id);

            if (!readStatus) {
                if (!await checkUserId()) return;

                const body = { user_id: await checkUserId(), notification_id: id }
                axios.post(`${process.env.REACT_APP_BASEURL}/notification/read-by`, body)
                    .then(res => {
                        if (res.data.success) {
                            let arr = [...notifications.notificationData];
                            arr.forEach(a => a.notification_id == id ? res.data.data : a)
                            dispatch({ type: FETCH_ALL_NOTIFICATIONS, payload: arr });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }
    }

    return (
        <div>
            <CustomIonicWrapper title='My Notifications' onBackClick={() => history.push('/home')}>
                {isPlatform('mobile') && (
                    <IonRefresher slot="fixed" onIonRefresh={getAllNotifications}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>
                )}

                <div className="notifications_cont">
                    <section className="notifications_section">
                        {notifications?.loading ? <CircularProgress /> : (
                            <>
                                {notifications.notificationData?.length <= 0 ? (
                                    <div className='notifications_notFound' >
                                        <img src={noNotificatiosGif} alt="No Notifications" />
                                        <h3>No Notifications Yet</h3>
                                    </div>
                                ) : (
                                    <>
                                        {notifications.notificationData?.map((n, i) => (
                                            <div className={`notifications_data_cont ${n?.read_status ? 'notifications_data_cont_read' : ''}`}
                                                onClick={() => onNotificationClick(n?.notification_id, n?.read_status)} key={i}
                                            >
                                                <div className='notifications_heading_cont' >
                                                    <h2>{JSON.parse(n?.notification_data)?.header}</h2>
                                                    <IonIcon icon={selectedNotification == n?.notification_id ? chevronUp : chevronDown} style={{ fontSize: 25, color: '#fff' }} />
                                                </div>
                                                <div className={`notifications_subHeading_cont ${selectedNotification == n?.notification_id ? 'notifications_subHeading_cont_active' : ''}`}>
                                                    <p>
                                                        {JSON.parse(n?.notification_data)?.data}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </section>
                </div>
            </CustomIonicWrapper>
        </div>
    )
}

export default Notifications