import { FETCH_ALL_NOTIFICATIONS, NOTIFICATIONS_UPDATE_LOADING } from "../type";

const initialState = {
    notificationData: [],
    loading: false
}

export const notificationReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case FETCH_ALL_NOTIFICATIONS:
            return state = {
                ...state,
                notificationData: actions.payload
            }

        case NOTIFICATIONS_UPDATE_LOADING:
            return state = {
                ...state,
                loading: actions.payload
            }

        default:
            return state;
    }
}