import { useEffect, useState } from 'react';
import '../../TruckInformation/TruckInfo1/TruckInfo1.css';
import './MyTripInfo3.css'
import { IonIcon } from '@ionic/react'
import { useHistory, useLocation } from 'react-router';
import { Button } from '@mui/material';
// CAMERA
import { Camera, CameraResultType } from '@capacitor/camera';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TRIP_INFO_3 } from '../../../redux/type';
// Components
import InfoPageTempelate from '../../../components/InfoPageTempelate/InfoPageTempelate';
import CustomIonicWrapper from '../../../components/CustomIonicWrapper/CustomIonicWrapper';
import CustomMuiInput from '../../../components/CustomMuiInput/CustomMuiInput';
// ICONs & SVGs
import { trashOutline } from 'ionicons/icons';

function MyTripInfo3() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const trip = useSelector(s => s.trip);

    const [comments, setComments] = useState('');
    // Docs
    const [billOfLadingImage, setBillOfLadingImage] = useState('');
    const [billOfLadingImagePreview, setBillOfLadingImagePreview] = useState('');
    const [rateConfImage, setRateConfImage] = useState('');
    const [rateConfImagePreview, setRateConfImagePreview] = useState('');

    useEffect(() => {
        if (pathname == '/my-trips/3') {
            setBillOfLadingImage(trip?.fData?.get('bill_of_lading') || '');
            setBillOfLadingImagePreview(trip.tripData.bill_of_lading);
            setRateConfImage(trip?.fData?.get('bill_of_lading') || '');
            setRateConfImagePreview(trip.tripData.rate_confirmation);
            setComments(trip.tripData.comments);
        }
    }, [pathname])

    const onAddImgToDialog = (e, heading) => {
        if (heading == 'lading') {
            setBillOfLadingImage(e.target.files[0]);
            setBillOfLadingImagePreview(URL?.createObjectURL(e.target.files[0]));
        } else {
            setRateConfImage(e.target.files[0]);
            setRateConfImagePreview(URL?.createObjectURL(e.target.files[0]));
        }
    }

    const onRemoveImgToDialog = (e, heading) => {
        if (heading == 'lading') {
            setBillOfLadingImage('');
            setBillOfLadingImagePreview('');
        } else {
            setRateConfImage('');
            setRateConfImagePreview('');
        }
    }

    const onNextTripInfo2 = () => {
        let fData = new FormData();
        fData.append('bill_of_lading', billOfLadingImage);
        fData.append('rate_confirmation', rateConfImage);

        dispatch({
            type: ADD_TRIP_INFO_3,
            tripData_3: { bill_of_lading: billOfLadingImagePreview, rate_confirmation: rateConfImagePreview, comments, fData },
            tripInfo3_done: true
        })

        return history.push('/my-trips/review');
    }

    // CAMERA
    const onTakePicture = async (heading) => {
        const image = await Camera.getPhoto({
            quality: 70,
            allowEditing: true,
            resultType: CameraResultType.DataUrl
        });
        const file = convertDataURLtoFile(image.dataUrl, 'My Truck Profit');

        if (heading == 'lading') {
            setBillOfLadingImage(file);
            setBillOfLadingImagePreview(URL.createObjectURL(file));
        } else {
            setRateConfImage(file);
            setRateConfImagePreview(URL.createObjectURL(file));
        }
    };

    const convertDataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    return (
        <CustomIonicWrapper title='Trip Information' onBackClick={() => history.push('/all-trips')}>
            <div className='truckInfo_cont' >
                <div className='truckInfo_stepImg tripInfo_stepImg3' ></div>

                <InfoPageTempelate btn1Label='Back' btn1Click={() => history.push('/my-trips/2')} btn2Click={onNextTripInfo2} arrowClass='tripInfo_data_arrow3' >
                    <CustomMuiInput shrink placeholder='Add Comments Here' lable='Comments' val={comments} setVal={setComments} multiline minRows={4} maxRows={7} />

                    {/* <Button variant="contained" component="label" disableElevation fullWidth className='viewDocs_uploadDialog_btn' >
                        Bill Of Lading
                        <input hidden accept="image/*" type="file" onChange={(e) => onAddImgToDialog(e, 'lading')} />
                    </Button> */}
                    <Button variant="contained" disableElevation fullWidth className='viewDocs_uploadDialog_btn' onClick={() => onTakePicture('lading')} >
                        Bill Of Lading
                    </Button>
                    {billOfLadingImagePreview && (
                        <div className='tripInfo3_previewImg' style={{ background: `url(${billOfLadingImagePreview}) no-repeat center/contain` }}>
                            <IonIcon icon={trashOutline} style={{ color: '#98002e' }} onClick={(e) => onRemoveImgToDialog(e, 'lading')} />
                        </div>
                    )}

                    {/* <Button variant="contained" component="label" disableElevation fullWidth className='viewDocs_uploadDialog_btn' >
                        Rate Confirmation
                        <input hidden accept="image/*" type="file" onChange={(e) => onAddImgToDialog(e, 'rateConf')} />
                    </Button> */}
                    <Button variant="contained" disableElevation fullWidth className='viewDocs_uploadDialog_btn' onClick={() => onTakePicture('rateConf')} >
                        Rate Confirmation
                    </Button>
                    {rateConfImagePreview && (
                        <div className='tripInfo3_previewImg' style={{ background: `url(${rateConfImagePreview}) no-repeat center/contain` }}>
                            <IonIcon icon={trashOutline} style={{ color: '#98002e' }} onClick={(e) => onRemoveImgToDialog(e, 'rateConf')} />
                        </div>
                    )}
                </InfoPageTempelate>
            </div>
        </CustomIonicWrapper>
    )
}

export default MyTripInfo3