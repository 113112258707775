import { useEffect, useState } from 'react';
import '../TruckInfo1/TruckInfo1.css';
import './TruckInfo3.css';
import { useHistory, useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TRUCK_INFO_3 } from '../../../redux/type';
// Components
import CustomIonicWrapper from '../../../components/CustomIonicWrapper/CustomIonicWrapper';
import DispatchFeeTypeToggle from '../../../components/DispatchFeeTypeToggle/DispatchFeeTypeToggle';
// ICONs & SVGs
import InfoPageTempelate from '../../../components/InfoPageTempelate/InfoPageTempelate';
import CustomMuiInput from '../../../components/CustomMuiInput/CustomMuiInput';

function TruckInfo3() {
    const history = useHistory();
    const dipatch = useDispatch();
    const { pathname } = useLocation();
    const truck = useSelector(s => s.truck);
    const { enqueueSnackbar } = useSnackbar();

    const [misc, setMisc] = useState('');
    const [carrierAuth, setCarrierAuth] = useState('');
    const [carrierAuthType, setCarrierAuthType] = useState(true);
    const [approxMiles, setApproxMiles] = useState('');
    const [fuelMileage, setFuelMileage] = useState('');
    const [IFTA, setIFTA] = useState('');

    useEffect(() => {
        if (pathname == '/truck-information/3') {
            setMisc(truck.truckData.misc)
            setCarrierAuth(truck.truckData.carrier_authority);
            setCarrierAuthType(truck.truckData.carrier_authority_type)
            setApproxMiles(truck.truckData.approx_miles_per_year)
            setFuelMileage(truck.truckData.fuel_mileage);
            setIFTA(truck.truckData.ifta_ucr)
        }
    }, [pathname])

    const onNextTruckInfo3 = () => {
        if (!misc) return enqueueSnackbar('Miscellaneous Not Found, Enter 0 IF Not Applicable !!!', { variant: 'error' })
        if (!approxMiles) return enqueueSnackbar('Approx Miles Per Year Not Found, Enter 0 IF Not Applicable !!!', { variant: 'error' })
        if (!fuelMileage) return enqueueSnackbar('Average Miles Per Gallon Not Found !!!', { variant: 'error' })
        if (fuelMileage <= 0) return enqueueSnackbar('Average Miles Per Gallon Should be more than 0 !!!', { variant: 'error' })

        dipatch({
            type: ADD_TRUCK_INFO_3,
            truckPayloadData: {
                misc, carrier_authority: carrierAuth, carrier_authority_type: carrierAuthType,
                approx_miles_per_year: approxMiles, fuel_mileage: fuelMileage, ifta_ucr: IFTA
            },
            truckInfoDone3: true
        })

        return history.push('/truck-information/review')
    }

    return (
        <CustomIonicWrapper title='Truck Final Details' onBackClick={() => history.push('/all-trucks')}>
            <div className='truckInfo_cont' >
                <div className='truckInfo_stepImg truckInfo_stepImg3' />

                <InfoPageTempelate btn1Label='Back' btn1Click={() => history.push('/truck-information/2')}
                    btn2Click={onNextTruckInfo3} arrowClass='truckInfo_data_arrow3' pageTempelateHeader='Enter Details Per Month'
                >
                    <CustomMuiInput lable='Miscellaneous' val={misc} setVal={setMisc} type='number' startAdornment='$' />

                    <DispatchFeeTypeToggle checked={carrierAuthType} setChecked={setCarrierAuthType} />
                    <CustomMuiInput lable='Carrier Authority' val={carrierAuth} setVal={setCarrierAuth} type='number' startAdornment={carrierAuthType ? '%' : '$'} />

                    <CustomMuiInput lable='Approx Miles Per Year' val={approxMiles} setVal={setApproxMiles} type='number' shrink placeholder='700 Miles' />
                    <CustomMuiInput lable='Average Miles Per Gallon' val={fuelMileage} setVal={setFuelMileage} autofocus type='number' shrink placeholder='Average Miles Per Gallon' />
                    <CustomMuiInput lable='IFTA / UCR / 2290' val={IFTA} setVal={setIFTA} autofocus type='number' startAdornment='$' />
                </InfoPageTempelate>
            </div>
        </CustomIonicWrapper>
    )
}

export default TruckInfo3
