import { useEffect, useRef, useState } from 'react';
import './LandingPage.css'
import { IonButton, IonButtons, IonContent, IonHeader, IonImg, IonModal, IonPage, IonTitle, IonToolbar, isPlatform } from "@ionic/react"
import { useHistory } from 'react-router'
import CustomIonicWrapper from '../../components/CustomIonicWrapper/CustomIonicWrapper';
// IMGS & SVGS
import logo from '../../imgs/landing.png'
import backImg from '../../imgs/header-back.png'
import StandardAndroidBtn from '../../components/StandardAndroidBtn/StandardAndroidBtn';

function LandingPage() {
    const history = useHistory();
    const page = useRef(null);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [presentingElement, setPresentingElement] = useState(null);

    useEffect(() => setPresentingElement(page.current), []);

    const onWillDismiss = (ev) => setIsOpenModal(false)

    return (
        <CustomIonicWrapper>
            <div className="landingPage_cont">
                <div>
                    <IonImg src={logo} alt="My Truck Profit" className="landing_img" />

                    <h1>Welcome to <br />My Truck Profit</h1>
                    <h4>Trannsports, Moving and Delivery Made Easy</h4>

                    {/* <IonButton className="landing_learnMore" fill='clear' mode='ios' onClick={() => setIsOpenModal(true)} >
                        Learn More
                    </IonButton> */}
                </div>

                <div className='landing_btnsCont' >
                    <StandardAndroidBtn label='Login' onClick={() => history.push('/login')} />

                    <div className="landing_btnsCont_register">
                        <StandardAndroidBtn label='Register' onClick={() => history.push('/signup')} />
                    </div>
                </div>

                <LearnMoreModal onWillDismiss={onWillDismiss} isOpen={isOpenModal} setIsOpen={setIsOpenModal}
                    presentingElement={presentingElement}
                />
            </div>
        </CustomIonicWrapper>
    )
}

export default LandingPage

const LearnMoreModal = ({ onWillDismiss, isOpen, setIsOpen, presentingElement }) => {
    return (
        <IonModal isOpen={isOpen} onWillDismiss={(ev) => onWillDismiss(ev)} presentingElement={presentingElement} swipeToClose={true} >
            <IonHeader class="ion-no-border" >
                <IonToolbar className='learnMoreModal_toolbar' >
                    <IonButtons slot="start">
                        <IonButton onClick={() => setIsOpen(false)}>
                            <img src={backImg} alt="close" />
                        </IonButton>
                    </IonButtons>
                    <IonTitle className={`${isPlatform('android') ? 'learnMoreModal_titleLeft' : ''}`} >
                        About My Truck Profit
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding learnMoreModal_content">
                <p>
                    Information about the services offered by My Truck Profit.
                </p>
            </IonContent>
        </IonModal>
    )
}