// USER
export const ADD_USER = 'ADD_USER';
export const ADD_COMPANY_DATA = 'ADD_COMPANY_DATA';
export const UPDATE_USER_LOGIN = 'UPDATE_USER_LOGIN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SUBSCRIPTION_COMPANY_DATA = 'SUBSCRIPTION_COMPANY_DATA';
export const UPDATE_PAYPAL_SUB_STATUS = 'UPDATE_PAYPAL_SUB_STATUS';
export const UPDATE_PAYPAL_SUBSCRIPTION_DATA = 'UPDATE_PAYPAL_SUBSCRIPTION_DATA';
export const FISRT_USER_LOGIN_MODAL_UPDATE = 'FISRT_USER_LOGIN_MODAL_UPDATE';

// IAP
export const UPDATE_IAP_SUB_STATUS = 'UPDATE_IAP_SUB_STATUS';

// TRUCK DATA
export const FETCH_ALL_TRUCKS = 'FETCH_ALL_TRUCKS';
export const ADD_TRUCK_INFO_1 = 'ADD_TRUCK_INFO_1';
export const ADD_TRUCK_INFO_2 = 'ADD_TRUCK_INFO_2';
export const ADD_TRUCK_INFO_3 = 'ADD_TRUCK_INFO_3';
export const RESET_TRUCK_INFO = 'RESET_TRUCK_INFO';

// TRIP DATA
export const FETCH_ALL_TRIPS = 'FETCH_ALL_TRIPS';
export const ADD_TRIP_INFO_1 = 'ADD_TRIP_INFO_1';
export const ADD_TRIP_INFO_2 = 'ADD_TRIP_INFO_2';
export const ADD_TRIP_INFO_3 = 'ADD_TRIP_INFO_3';
export const RESET_TRIP_INFO = 'RESET_TRIP_INFO';

// NOTIFICATION DATA
export const FETCH_ALL_NOTIFICATIONS = 'FETCH_ALL_NOTIFICATIONS';
export const NOTIFICATIONS_UPDATE_LOADING = 'NOTIFICATIONS_UPDATE_LOADING';
