import { forwardRef } from 'react';
import './EditModal.css';
import { DialogContent, DialogTitle, Slide, Dialog, DialogActions, Button, CircularProgress } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditModal({ open, setOpen, onUpdate, loading, editModalColor, editModalText, className, hideUpdateBtn, children }) {
    return (
        <Dialog fullWidth maxWidth='sm' open={open} TransitionComponent={Transition}
            className={`editModal_dialog ${className ? className : ''}`}
            scroll='paper'
        >
            <DialogTitle style={{ paddingBottom: 0 }} >
                <div className='editModal_dash' />
            </DialogTitle>
            <DialogContent style={{ paddingBottom: 10 }} >
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} sx={{ textTransform: 'capitalize', color: '#00235F' }} disabled={loading} >
                    Cancel
                </Button>
                {!hideUpdateBtn && (
                    <Button onClick={onUpdate} sx={{ textTransform: 'capitalize', color: editModalColor || 'darkgreen' }} disabled={loading} >
                        {loading ? <CircularProgress size={18} sx={{ color: 'darkgreen' }} /> : `${editModalText || 'Update'}`}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
