import { Fragment, useEffect, useState } from 'react';
import '../AllDocuments/AllDocuments.css';
import './ViewDocument.css';
import { useHistory, useLocation } from "react-router";
import { IonIcon } from '@ionic/react';
import { useSnackbar } from 'notistack';
import { Button, CircularProgress, Dialog, Fab, Skeleton } from '@mui/material';
import axios from 'axios';
// Storage
import { checkUserId } from '../../../redux/actions/exportFunctions';
// Components
import CustomIonicWrapper from "../../../components/CustomIonicWrapper/CustomIonicWrapper"
import EditModal from '../../../components/EditModal/EditModal';
import CustomMuiInputNormal from '../../../components/CustomMuiInput/CustomMuiInputNormal';
import NoDataImage from '../../../components/NoDataImage/NoDataImage';
// ICONs & SVGs
import { addOutline, closeCircleOutline, trashOutline } from 'ionicons/icons';
import tripImg from '../../../imgs/homePage/view-documents.svg'
import pdfPreview from '../../../imgs/pdf-preview.svg';

function ViewDocument() {
    const history = useHistory();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [docDeleteID, setDocDeleteID] = useState('');
    const [allDocuments, setAllDocuments] = useState([]);
    const [billOfLading, setBillOfLading] = useState('');
    const [rateConfirmation, setRateConfirmation] = useState('');
    // Dialog
    const [showImgDialog, setShowImgDialog] = useState(false);
    const [dialogImg, setDialogImg] = useState(null);
    // IMG UploadDialog
    const [imgUploadDialogLoading, setImgUploadDialogLoading] = useState(false);
    const [imgUploadDialog, setImgUploadDialog] = useState(false);
    const [imgUploadDocName, setImgUploadDocName] = useState('');
    const [imgUploadDocImage, setImgUploadDocImage] = useState('');
    const [imgUploadDocImagePreview, setImgUploadDocImagePreview] = useState('');

    useEffect(() => {
        if (location.pathname == '/view-documents') {
            getAllDocuments();
            setAllDocuments([]);
            setBillOfLading('');
            setRateConfirmation('');
        }
    }, [location.pathname])

    const getAllDocuments = async () => {
        if (!await checkUserId()) return;

        setLoading(true);
        const body = { trip_id: location.state?.body?.tripId || '', user_id: await checkUserId(), document_type: location.state?.body?.heading }

        await axios.post(`${process.env.REACT_APP_BASEURL}/document/all-documents`, body)
            .then(res => {
                if (res.data.success) {
                    setAllDocuments(res.data.data);
                    let arr = [...res.data.data];
                    for (let i = 0; i < 1; i++) {
                        setBillOfLading(arr[i]?.bill_of_lading);
                        setRateConfirmation(arr[i]?.rate_confirmation);
                    }
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }

    const onCardClick = (cardData) => {
        if (cardData.content_type == 'application/pdf') return window.open(cardData.document_path, '_blank')
        setShowImgDialog(true);
        setDialogImg(cardData.document_path);
    }

    const onAddImgToDialog = e => {
        if (e.target.files.length !== 1) return;
        if (Math.round(e.target.files[0].size / 1024) > 10240) return enqueueSnackbar('Document Should Be Less Than 10 MB !!!', { variant: 'error' })

        setImgUploadDocImage(e.target.files[0]);
        if (e.target.files[0]?.type == 'application/pdf') {
            setImgUploadDocImagePreview(pdfPreview);
        } else {
            setImgUploadDocImagePreview(URL?.createObjectURL(e.target.files[0]));
        }
    }

    const onDeleteImg = async (e, docId) => {
        e.stopPropagation();
        if (!docId) return;

        setDocDeleteID(docId);
        const body = { document_id: docId }

        await axios.put(`${process.env.REACT_APP_BASEURL}/document/delete`, body)
            .then(res => {
                if (res.data.success) {
                    let arr = allDocuments.filter(a => a.document_id !== docId)
                    setAllDocuments(arr);
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setDocDeleteID('');
            })
            .catch(err => {
                console.log(err);
                setDocDeleteID('');
            })
    }

    const onUpload = async () => {
        if (!imgUploadDocName) return enqueueSnackbar('Please Add Document Name !!!', { variant: 'error' });
        if (!imgUploadDocImage) return enqueueSnackbar('Please Add an Image !!!', { variant: 'error' });
        if (!location.state?.body?.heading) return enqueueSnackbar('No Heading Found !!!', { variant: 'error' });

        setImgUploadDialogLoading(true);

        const fData = new FormData();
        fData.append('image', imgUploadDocImage)
        fData.append('document_heading', location.state.body.heading)
        fData.append('document_name', imgUploadDocName)
        fData.append('user_id', await checkUserId())
        if (location.state.body?.tripId) fData.append('trip_id', location.state.body.tripId)

        await axios.post(`${process.env.REACT_APP_BASEURL}/document/upload`, fData)
            .then(res => {
                if (res.data.success) {
                    setAllDocuments([...allDocuments, res.data.data]);
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setImgUploadDialogLoading(false);
                setImgUploadDialog(false);
                setImgUploadDocName('');
                setImgUploadDocImage('');
                setImgUploadDocImagePreview('')
            })
            .catch(err => {
                console.log(err);
                setImgUploadDialogLoading(false);
            })
    }

    return (
        <CustomIonicWrapper title={location?.state?.body?.heading} onBackClick={() => history.push('/all-documents')}>
            <div className="myDocs_container">
                <section className="myDocs_section viewDocs_section">
                    {loading ? (
                        <>
                            {Array(4).fill('').map((a, i) => <ViewDocumentCardSkeleton key={i} />)}
                        </>
                    ) : (
                        <>
                            {allDocuments?.length > 0 || !!billOfLading || !!rateConfirmation ? (
                                <>
                                    {allDocuments.map((a, i) => (
                                        <Fragment key={i} >
                                            {a?.document_path ? (
                                                <ViewDocumentCard heading={a.document_name}
                                                    imgUrl={a.content_type == 'application/pdf' ? pdfPreview : a.document_path}
                                                    onClick={() => onCardClick(a)}
                                                    onDelClick={(e) => onDeleteImg(e, a.document_id)}
                                                    delLoading={docDeleteID == a.document_id}
                                                />
                                            ) : null}
                                        </Fragment>
                                    ))}

                                    {billOfLading && (
                                        <ViewDocumentCard key='Bill Of Lading' heading='Bill Of Lading' imgUrl={billOfLading}
                                            onClick={() => onCardClick(billOfLading)}
                                        />
                                    )}
                                    {rateConfirmation && (
                                        <ViewDocumentCard key='Rate Confirmation' heading='Rate Confirmation' imgUrl={rateConfirmation}
                                            onClick={() => onCardClick(rateConfirmation)}
                                        />
                                    )}
                                </>
                            ) : (
                                <NoDataImage img={tripImg} heading='No Documents Found... Add Documents' />
                            )}
                        </>
                    )}
                </section>

                <Fab variant='extended' className='viewDocs_addFab' onClick={() => setImgUploadDialog(true)} >
                    <IonIcon icon={addOutline} />
                    <p>Add Documents</p>
                </Fab>
            </div>

            {/* IMG DIALOG */}
            <Dialog fullScreen open={showImgDialog} className='viewDocs_imgDialog' >
                <IonIcon icon={closeCircleOutline} style={{ color: '#00235F' }} className='viewDocs_imgDialog_closeIcon' onClick={() => setShowImgDialog(false)} />

                <img src={dialogImg} alt="" className='viewDocs_imgDialog_img' />
            </Dialog>

            {/* IMG UPLOAD MODAL */}
            <EditModal open={imgUploadDialog} setOpen={setImgUploadDialog} className='viewDocs_uploadDialog' onUpdate={onUpload} editModalText='Add Document' loading={imgUploadDialogLoading}>
                <CustomMuiInputNormal lable='Document Name' val={imgUploadDocName} setVal={setImgUploadDocName} variant='outlined' />

                <Button variant="contained" component="label" disableElevation fullWidth className='viewDocs_uploadDialog_btn' >
                    Select Document
                    <input hidden accept="image/*, application/pdf" type="file" onChange={onAddImgToDialog} />
                </Button>

                {imgUploadDocImagePreview && <img src={imgUploadDocImagePreview} alt={imgUploadDocName} className='viewDocs_uploadDialog_previewImg' />}
            </EditModal>
        </CustomIonicWrapper>
    )
}

export default ViewDocument

const ViewDocumentCard = ({ heading, imgUrl, onClick, onDelClick, delLoading }) => (
    <div className="viewDocumentCard" onClick={onClick} >
        <div className="viewDocumentCard_header">
            <h3>{heading}</h3>
            {onDelClick && (
                <>
                    {delLoading ? (
                        <CircularProgress sx={{ color: '#98002e' }} size={18} />
                    ) : (
                        <IonIcon icon={trashOutline} style={{ color: '#98002e' }} onClick={onDelClick ? onDelClick : () => { }} />
                    )}
                </>
            )}
        </div>
        <img src={imgUrl} alt="" />
    </div>
)

const ViewDocumentCardSkeleton = () => (
    <div className="viewDocumentCard" >
        <div className="viewDocumentCard_header">
            <Skeleton width='60%' height={40} animation="wave" />
            <Skeleton width={25} height={40} animation="wave" />
        </div>
        <Skeleton variant="rounded" width='100%' height={190} animation="wave" />
    </div>
)
