import "../SignUp/Signup.css";
import './Login.css'
import { useState } from 'react';
import { IonButton, IonImg } from '@ionic/react'
import { useHistory } from "react-router";
import axios from "axios";
import { Preferences } from '@capacitor/preferences';
import { useSnackbar } from "notistack";
// Redux
import { useDispatch } from "react-redux";
import { ADD_USER, UPDATE_USER_LOGIN } from "../../redux/type";
// Components
import CustomIonicWrapper from "../../components/CustomIonicWrapper/CustomIonicWrapper";
import StandardAndroidBtn from "../../components/StandardAndroidBtn/StandardAndroidBtn";
import CustomMuiInputNormal from "../../components/CustomMuiInput/CustomMuiInputNormal";
// IMGS & SVGS
import SignUplogo from '../../imgs/signUp-logo.png'
// import { logoApple, logoGoogle } from "ionicons/icons";

function Login() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const onLoginClick = async () => {
        if (!email, !password) return enqueueSnackbar('Please fill all the details', { variant: 'error' })

        setLoading(true);
        const body = { email, password }

        await axios.post(`${process.env.REACT_APP_BASEURL}/user/signin`, body)
            .then(res => {
                if (res.data.success) {
                    setData(res.data.data[0]);

                    dispatch({ type: ADD_USER, payload: res.data.data[0] });
                    dispatch({ type: UPDATE_USER_LOGIN, payload: true });
                    setEmail('');
                    setPassword('')
                    enqueueSnackbar('Login Successfull', { variant: 'success' })
                    history.push('/home');
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                return setLoading(false);
            })
            .catch(err => {
                console.log(err);
                return setLoading(false);
            })
    }

    const setData = async (data) => await Preferences.set({ key: 'truckola_users', value: JSON.stringify(data) });

    return (
        <CustomIonicWrapper title='Login' onBackClick={() => history.push('/')}>
            <IonImg src={SignUplogo} alt="My Truck Profit" className="signup_logo" />
            <div className="login_cont">
                <div className="login_data_cont">
                    <CustomMuiInputNormal lable='Email ID' val={email} setVal={setEmail} type='email' autofocus />
                    <CustomMuiInputNormal lable='Password' val={password} setVal={setPassword} type='password' />

                    <div className="login_btnCont">
                        <StandardAndroidBtn label='Login' onClick={onLoginClick} loading={loading} />
                    </div>

                    <IonButton className="login_forgotPass" onClick={() => history.push('/forgot-password')} fill='clear' mode='ios'>
                        Forgot password?
                    </IonButton>

                    <IonButton className="login_newUser_btn" onClick={() => history.push('/signup')} fill='clear' mode='ios'>
                        New User ? Register
                    </IonButton>

                    {/* <p className="login_newUser_text">
                        or, Login with
                    </p>

                    <div className="signup_logoCont" >
                        <IonButton fill="clear" mode="ios">
                            <IonIcon icon={logoGoogle} />
                        </IonButton>

                        <IonButton fill="clear" mode="ios">
                            <IonIcon icon={logoApple} />
                        </IonButton>
                    </div> */}
                </div>
            </div>
        </CustomIonicWrapper>
    )
}

export default Login