import { useState } from 'react';
import '../../TruckInformation/TruckInfo1/TruckInfo1.css';
import '../../TruckInformation/ReviewInputs/ReviewInputs.css';
import { useHistory } from 'react-router';
import axios from 'axios';
import moment from 'moment';
import { checkUserId } from '../../../redux/actions/exportFunctions';
import { useSnackbar } from 'notistack';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RESET_TRIP_INFO } from '../../../redux/type';
// Components
import CustomIonicWrapper from '../../../components/CustomIonicWrapper/CustomIonicWrapper';
import InfoPageTempelate from '../../../components/InfoPageTempelate/InfoPageTempelate';

function ReviewMyTripData() {
    const history = useHistory();
    const dispatch = useDispatch();
    const allTrucks = useSelector(s => s.truck.getAllTrucks);
    const trip = useSelector(s => s.trip.tripData);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false)

    const onConfirm = async () => {
        setLoading(true);
        const body = { user_id: await checkUserId(), ...trip }
        const fData = new FormData();
        Object.keys(body).forEach(b => {
            if (b == 'bill_of_lading' || b == 'rate_confirmation') return;
            fData.append(b, body[b]);
        })
        fData.append('bill_of_lading', trip.fData?.get('bill_of_lading')?.size > 0 ? trip.fData?.get('bill_of_lading') : '');
        fData.append('rate_confirmation', trip.fData?.get('rate_confirmation')?.size > 0 ? trip.fData?.get('rate_confirmation') : '');

        await axios.post(`${process.env.REACT_APP_BASEURL}/trip/add`, fData)
            .then(res => {
                if (res.data.success) {
                    dispatch({ type: RESET_TRIP_INFO });
                    enqueueSnackbar('Trip Added Successfully', { variant: 'success' });
                    history.push('/all-trips');
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                return setLoading(false);
            })
            .catch(err => {
                console.log(err);
                return setLoading(false);
            })
    }

    return (
        <CustomIonicWrapper title='Trip Details' onBackClick={() => history.push('/all-trips')}>
            <div className='truckInfo_cont reviewInputs_cont' >
                <InfoPageTempelate btn1Label='Back' btn1Click={() => history.push('/my-trips/3')} btn2Label='Save' btn2Click={onConfirm} loading={loading} >
                    <TruckRevieData heading='Truck Name' amt={allTrucks?.filter(a => a.truck_id == trip.truck_id)[0]?.truck_no} />
                    {/* <TruckRevieData heading='Date Of Trip' amt={moment(trip.date_of_trip).format("DD/MM/YYYY hh:mm a")} /> */}
                    <TruckRevieData heading='Date Of Trip' amt={moment(trip.date_of_trip).format("DD/MM/YYYY")} />
                    <TruckRevieData heading='Profit' amt={trip.profit} />
                    <TruckRevieData heading='Trip Number' amt={trip.trip_number} />
                    <TruckRevieData heading='Rate' amt={trip.rate} />
                    <TruckRevieData heading='From City' amt={trip.from_city} />
                    <TruckRevieData heading='From State' amt={trip.from_state} />
                    <TruckRevieData heading='To City' amt={trip.to_city} />
                    <TruckRevieData heading='To State' amt={trip.to_state} />
                    <TruckRevieData heading='Total Loaded Miles' amt={trip.total_loaded_miles} />
                    <TruckRevieData heading='Comments' amt={trip.comments} />
                    {trip?.bill_of_lading && (
                        <div className='reviewInputs_Imgcont'>
                            <h3>Bill Of Lading</h3>
                            <img src={trip?.bill_of_lading} alt='Bill Of Lading' />
                        </div>
                    )}

                    {trip?.rate_confirmation && (
                        <div className='reviewInputs_Imgcont'>
                            <h3>Rate Confirmation</h3>
                            <img src={trip?.rate_confirmation} alt='Rate Confirmation' />
                        </div>
                    )}

                </InfoPageTempelate>
            </div>
        </CustomIonicWrapper>
    )
}

export default ReviewMyTripData;

const TruckRevieData = ({ heading, amt }) => (
    <div className='truckInfo_review_data'>
        <h3>{heading}</h3>
        <p>{amt}</p>
    </div>
)