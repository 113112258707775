import { useHistory } from "react-router"
import '../TripDocuments/TripDocuments.css';
import './AllDocuments.css';
import { IonRippleEffect } from "@ionic/react";
// Components
import CustomIonicWrapper from "../../../components/CustomIonicWrapper/CustomIonicWrapper"
// ICONs & SVGs
import docImg from '../../../imgs/document.svg'

function AllDocuments() {
    const history = useHistory();

    const onDocClick = (link) => {
        if (link == '/view-documents') {
            const body = { heading: 'My Documents', tripNumber: '', tripId: '' }
            history.push(link, { body })
        } else {
            history.push(link);
        }
    }

    return (
        <CustomIonicWrapper title='Documents' onBackClick={() => history.push('/home')}>
            <div className="myDocs_container">
                <section className="myDocs_section">
                    <div className="myDocs_docCard_cont allDocs_docCard_cont">
                        {documentCardArr?.map((d, i) => (
                            <DocumentCard heading={d.heading} onClick={() => onDocClick(d.link)} key={i} />
                        ))}
                    </div>
                </section>
            </div>
        </CustomIonicWrapper>
    )
}

export default AllDocuments

const DocumentCard = ({ heading, onClick }) => (
    <div className="myDocs_docCard ion-activatable" onClick={onClick}>
        <img src={docImg} alt="Document" />
        <h4>{heading}</h4>
        <IonRippleEffect />
    </div>
)

const documentCardArr = [
    {
        heading: 'My Documents',
        link: '/my-documents'
    },
    {
        heading: 'Documents of My Trips',
        link: '/trip-documents'
    },
]