import { useEffect, useState } from 'react';
import '../../TruckInformation/TruckInfo1/TruckInfo1.css';
import './MyTripInfo2.css'
import { useHistory, useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TRIP_INFO_2 } from '../../../redux/type';
// Components
import CustomIonicWrapper from '../../../components/CustomIonicWrapper/CustomIonicWrapper';
// ICONs & SVGs
import InfoPageTempelate from '../../../components/InfoPageTempelate/InfoPageTempelate';
import CustomMuiInputNormal from '../../../components/CustomMuiInput/CustomMuiInputNormal';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function MyTripInfo2() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const trip = useSelector(s => s.trip);
    const { enqueueSnackbar } = useSnackbar();

    const [fromCity, setFromCity] = useState('');
    const [fromState, setFromState] = useState('');
    const [toCity, setToCity] = useState('');
    const [toState, setToState] = useState('');
    const [totalMiles, setTotalMiles] = useState('');

    useEffect(() => {
        if (pathname == '/my-trips/2') {
            setFromCity(trip.tripData.from_city);
            setFromState(trip.tripData.from_state);
            setToCity(trip.tripData.to_city);
            setToState(trip.tripData.to_state);
            setTotalMiles(trip.tripData.total_loaded_miles);
        }
    }, [pathname])

    const onNextTripInfo2 = () => {
        if (!fromCity || !fromState || !toCity || !toState || !totalMiles) return enqueueSnackbar('Please Fill All The Details !!!', { variant: 'error' })

        dispatch({
            type: ADD_TRIP_INFO_2,
            tripData_2: { from_city: fromCity.toLocaleLowerCase(), from_state: fromState, to_city: toCity.toLocaleLowerCase(), to_state: toState, total_loaded_miles: totalMiles },
            tripInfo2_done: true
        })

        return history.push('/my-trips/3');
    }

    return (
        <CustomIonicWrapper title='Trip Information' onBackClick={() => history.push('/all-trips')}>
            <div className='truckInfo_cont' >
                <div className='truckInfo_stepImg tripInfo_stepImg2' ></div>

                <InfoPageTempelate btn1Label='Back' btn1Click={() => history.push('/my-trips/1')} btn2Click={onNextTripInfo2} arrowClass='tripInfo_data_arrow2' >
                    {/* <CustomMuiInputNormal lable='To State' val={toState} setVal={setToState} /> */}
                    {/* <CustomMuiInputNormal lable='From State' val={fromState} setVal={setFromState} /> */}
                    <CustomMuiInputNormal lable='From City' val={fromCity} setVal={setFromCity} />
                    <FormControl fullWidth variant='standard' sx={{ marginTop: '10px' }} >
                        <InputLabel sx={{ color: '#000' }} >From State</InputLabel>
                        <Select value={fromState} label="From State" onChange={e => setFromState(e.target.value)} >
                            {statesArr?.map((t, i) => <MenuItem key={i} value={t}>{t}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <CustomMuiInputNormal lable='To City' val={toCity} setVal={setToCity} />
                    <FormControl fullWidth variant='standard' sx={{ marginTop: '10px' }} >
                        <InputLabel sx={{ color: '#000' }} >To State</InputLabel>
                        <Select value={toState} label="From State" onChange={e => setToState(e.target.value)} >
                            {statesArr?.map((t, i) => <MenuItem key={i} value={t}>{t}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <CustomMuiInputNormal lable='Total Loaded Miles' val={totalMiles} setVal={setTotalMiles} type='number' />
                </InfoPageTempelate>
            </div>
        </CustomIonicWrapper>
    )
}

export default MyTripInfo2

const statesArr = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA",
    "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN",
    "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
]