import { forwardRef } from 'react';
import './InfoModal.css'
import { DialogContent, DialogTitle, Slide, Dialog } from '@mui/material';
import { IonIcon } from '@ionic/react';
// ICONs
import { closeCircleOutline } from 'ionicons/icons';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InfoModal({ open, setOpen, onClose, className, children }) {
    return (
        <Dialog fullWidth maxWidth='lg' open={open} onClose={onClose ? onClose : () => setOpen(false)}
            TransitionComponent={Transition} className={`infoModal_dialog ${className ? className : ''}`} scroll='paper'
        >
            <DialogTitle style={{ paddingBottom: 0, position: 'relative' }} >
                <div className='infoModal_dash' />
                <IonIcon icon={closeCircleOutline} className='infoModal_closeIcon' onClick={() => setOpen(false)} />
            </DialogTitle>
            <DialogContent style={{ paddingBottom: 10 }} >
                {children}
            </DialogContent>
        </Dialog>
    );
}
