import './HomePage.css'
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from "react-router";
import { checkUserId } from '../../redux/actions/exportFunctions';
import { Glassfy } from 'capacitor-plugin-glassfy';
import { isPlatform } from '@ionic/react';
import axios from 'axios';
import moment from 'moment';
// REDUCER
import { useDispatch, useSelector } from 'react-redux';
import { 
    FETCH_ALL_NOTIFICATIONS, FISRT_USER_LOGIN_MODAL_UPDATE, NOTIFICATIONS_UPDATE_LOADING, SUBSCRIPTION_COMPANY_DATA, 
    UPDATE_IAP_SUB_STATUS, UPDATE_PAYPAL_SUBSCRIPTION_DATA, UPDATE_PAYPAL_SUB_STATUS 
} from '../../redux/type';
import CustomIonicWrapper from '../../components/CustomIonicWrapper/CustomIonicWrapper';
// Components
import HomePageCard from '../../components/HomePageCard/HomePageCard';
import InfoModal from '../../components/InfoModal/InfoModal';
// import { Fab } from '@mui/material';
// IMGS & SVGS
import addTruck from '../../imgs/homePage/add-truck.svg'
import viewDocuments from '../../imgs/homePage/view-documents.svg'
import ChatWithUs from '../../imgs/homePage/chat-withUs.svg'
import ViewTrips from '../../imgs/homePage/view-trips.svg'
import TripProfits from '../../imgs/homePage/trip-profit.svg'
import CustomerService from '../../imgs/homePage/customer-service.svg'
import subscribe from '../../imgs/homePage/subscribe-again.gif'
import freeTrial from '../../imgs/free-trial.gif'

function HomePage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const trialModalShow = useSelector(s => s.user.showFirstLoginModal)
    const { enqueueSnackbar } = useSnackbar();

    const [openSubscribeModal, setOpenSubscribeModal] = useState(false);

    useEffect(() => {
        if (location.pathname == '/home') {
            getAllNotifications();
            fetchUserSubscriptionData();
        }
    }, [location.pathname])

    useEffect(() => {
        if (isPlatform('ios')) {
            initializeGlassify();
        };
    }, [])

    const initializeGlassify = async () => {
        try {
            await Glassfy.initialize({ apiKey: process.env.REACT_APP_GLASSIFY_APIKEY, watcherMode: false });
        } catch (e) {
            console.log('error', e);
        }
    }

    const getGlassifyPermissions = async (subID, data) => {
        if (!isPlatform('ios')) return;

        try {
            const permissions = await Glassfy.permissions();
            const permission = permissions.all?.find(p => p?.permissionId === 'Premium - $14.99')            
            
            dispatch({ 
                type: UPDATE_PAYPAL_SUBSCRIPTION_DATA, 
                payload: {
                    iapData: data,
                    permission: permission
                }
            })

            if(permission?.isValid) {
                updateSubscriptionStatusinDB(subID, 1)
            } else {
                updateSubscriptionStatusinDB(subID, 0)
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const getAllNotifications = async () => {
        if (!await checkUserId()) return;

        dispatch({ type: NOTIFICATIONS_UPDATE_LOADING, payload: true })

        const body = { user_id: await checkUserId() }
        await axios.post(`${process.env.REACT_APP_BASEURL}/notification/get-all`, body)
            .then(res => {
                if (res.data.success) {
                    dispatch({ type: FETCH_ALL_NOTIFICATIONS, payload: res.data.data });
                    dispatch({ type: NOTIFICATIONS_UPDATE_LOADING, payload: false });
                }
            })
            .catch(err => {
                console.log(err);
                dispatch({ type: NOTIFICATIONS_UPDATE_LOADING, payload: false })
            })
    }

    const fetchUserSubscriptionData = async () => {
        if (!await checkUserId()) return enqueueSnackbar('Oops, Something Went Wrong !!!', { variant: 'error' });

        const body = { user_id: await checkUserId() }
        await axios.post(`${process.env.REACT_APP_BASEURL}/subscription/get`, body)
            .then(res => {
                if (res.data.success) {
                    dispatch({ type: SUBSCRIPTION_COMPANY_DATA, payload: res.data.data })
                    if (res.data.data?.paypal_data && res.data.data?.type_of_subscription == 'Monthly') {
                        if(JSON.parse(res.data.data?.paypal_data)?.subscriptionID) {
                            fetchSubscriptionStatusFromPaypal(JSON.parse(res.data.data?.paypal_data)?.subscriptionID)
                        } else {
                            getGlassifyPermissions(JSON.parse(res.data.data?.sub_id), JSON.parse(res.data.data?.paypal_data));
                        }
                    } else {
                        dispatch({ type: UPDATE_PAYPAL_SUB_STATUS, payload: undefined })
                        if (moment(new Date()).isAfter(res.data.data?.validity)) setOpenSubscribeModal(true); // validity > current date
                    }
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const fetchSubscriptionStatusFromPaypal = async (subID) => {
        await axios.post('https://api-m.paypal.com/v1/oauth2/token', new URLSearchParams({ 'grant_type': 'client_credentials' }),
            {
                headers: { 'Accept': 'application/json', 'Accept-Language': 'en_US' },
                auth: { username: process.env.REACT_APP_PAYPAL_CLIENT_ID, password: process.env.REACT_APP_PAYPAL_SECRET }
            }
        )
            .then(async res => {
                // Check Status
                await axios.get(`https://api-m.paypal.com/v1/billing/subscriptions/${subID}`, {
                    headers: { "Authorization": `Bearer ${res.data.access_token}` }
                })
                    .then(res => {
                        dispatch({ type: UPDATE_PAYPAL_SUB_STATUS, payload: res.data?.status })
                        dispatch({ type: UPDATE_PAYPAL_SUBSCRIPTION_DATA, payload: res.data })
                        if (res.data?.status !== 'ACTIVE') setOpenSubscribeModal(true);
                    })
                    .catch(err => console.log(err, 'Error @Paypal Subscription Status'))
            })
            .catch(err => console.log(err, 'Error @Paypal Access Token'))

    }

    const updateSubscriptionStatusinDB = async (sub_id, status) => {
        if (!await checkUserId()) return;

        const body = { user_id: await checkUserId(), sub_id: sub_id, status }

        await axios.post(`${process.env.REACT_APP_BASEURL}/subscription/update-active-status`, body)
            .then(res => {
                if (res.data.success) {
                    if (status == 1) {
                        dispatch({ type: UPDATE_IAP_SUB_STATUS, payload: 'ACTIVE' })
                    } else {
                        dispatch({ type: UPDATE_IAP_SUB_STATUS, payload: 'SUSPENDED' })
                    }
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onNavigate = (loc) => history.push(loc);
    return (
        <CustomIonicWrapper title='My Truck Profit' onBackClick={() => history.push('/')} showNav showNavRight>
            <section className="homePage_section">
                <div className="homePage_container">
                    <div className="homePage_cont_sec1">
                        <HomePageCard img={TripProfits} title='Calculate Trip Profits' onClick={() => onNavigate('/pay-driver')} />
                        <HomePageCard img={addTruck} title='My Trucks' onClick={() => onNavigate('/all-trucks')} />
                    </div>
                    <div className="homePage_cont_sec2">
                        <HomePageCard img={viewDocuments} title='My Documents' onClick={() => onNavigate('/all-documents')} />
                        <HomePageCard img={ViewTrips} title='My Trips' onClick={() => onNavigate('/all-trips')} />
                    </div>
                    <div className="homePage_cont_sec2">
                        <HomePageCard img={CustomerService} title='Services' onClick={() => onNavigate('/services')} />
                        <HomePageCard img={ChatWithUs} title='Contact Us' onClick={() => window.open("mailto:info@mytruckprofit.com", '_blank')} />
                    </div>
                </div>

                {/* <Fab size="small" className='homePageContactUs_fab' onClick={() => window.open("mailto:info@mytruckprofit.com", '_blank')} >
                    <img src={ChatWithUs} />
                </Fab> */}

                <InfoModal open={openSubscribeModal} setOpen={setOpenSubscribeModal}>
                    <div className='homePage_SubscribeModal_cont' >
                        <img src={subscribe} />
                        <h3>Please Subscribe To Continue The Services !!!</h3>
                    </div>
                </InfoModal>

                <InfoModal open={trialModalShow} onClose={() => dispatch({ type: FISRT_USER_LOGIN_MODAL_UPDATE, payload: false })}>
                    <div className='homePage_SubscribeModal_cont' >
                        <img src={freeTrial} />
                        <h3>Your 14 Days Free Trial Starts Today !!!</h3>
                    </div>
                </InfoModal>

            </section>
        </CustomIonicWrapper>
    )
}

export default HomePage;