import { useLayoutEffect, useState } from 'react';
import './StandardHeader.css';
import { IonButtons, IonHeader, IonIcon, IonMenuToggle, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { Badge, IconButton } from '@mui/material';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
// ICONs & SVGs
import { menu, notifications } from 'ionicons/icons';
import backImg from '../../imgs/header-back.png'

const StandardHeader = ({ title, onBackClick, showNav, headerFontSize, showNavRight }) => {
  const history = useHistory();
  const { notificationData } = useSelector(s => s.notification);

  const [showNavMobile, setShowNavMobile] = useState(false)

  useLayoutEffect(() => {
    setShowNavMobile(window.innerWidth <= 991);
  }, [])
  window.addEventListener("resize", () => setShowNavMobile(window.innerWidth <= 991))

  return (
    <>
      {showNavMobile && (
        <IonHeader className="ion-no-border standardHeader_header_cont">
          <IonToolbar className={`standardHeader_toolbar 
              ${isPlatform('android') || isPlatform('desktop') ? 'standardHeader_toolbar_centerTitle' : ''} 
              ${isPlatform('android') && showNavRight ? 'standardHeader_toolbar_centerTitle_with_rightIcon' : ''} 
              ${showNav && isPlatform('android') ? 'standardHeader_toolbar_navIcon_centerTitle' : ''}
            `}
          >
            {onBackClick && (
              <IonButtons slot="start">
                {showNav ? (
                  <IonMenuToggle>
                    <IconButton className='header_navBtn'>
                      <IonIcon slot="start" icon={menu} style={{ color: '#000', margin: 0, fontSize: 25 }} />
                    </IconButton>
                  </IonMenuToggle>
                ) : (
                  <IconButton className='header_backBtn' onClick={onBackClick} >
                    <img src={backImg} alt="close" />
                  </IconButton>
                )}
              </IonButtons>
            )}
            <IonTitle style={{ fontSize: headerFontSize || 20 }} >{title}</IonTitle>
            {showNavRight && (
              <IconButton className='header_notification' slot='end' onClick={() => history.push('/notifications')}>
                <Badge variant="dot" invisible={notificationData?.some(n => n.read_status == false) ? false : true} >
                  <IonIcon slot="end" icon={notifications} style={{ color: '#00235F', margin: 0, fontSize: 25 }} />
                </Badge>
              </IconButton>
            )}
          </IonToolbar>
        </IonHeader>
      )}
    </>
  )
};

export default StandardHeader;
