import { IonRippleEffect } from '@ionic/react';
import './HomePageCard.css';

function HomePageCard({ img, title, bgGrey, onClick }) {
    return (
        <div className={`ion-activatable homePage_card ${bgGrey ? 'homePage_card_greyBg' : ''}`} onClick={onClick} >
            <img src={img} alt={img} />
            <p>{title}</p>
            <IonRippleEffect />
        </div>
    )
}

export default HomePageCard