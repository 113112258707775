import "../SignUp/Signup.css";
import './PayYourDriver.css'
import { FETCH_ALL_TRUCKS } from "../../redux/type";
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { checkUserId } from "../../redux/actions/exportFunctions";
import { useSnackbar } from "notistack";
import axios from "axios";
// Components
import CustomIonicWrapper from "../../components/CustomIonicWrapper/CustomIonicWrapper";
import StandardAndroidBtn from "../../components/StandardAndroidBtn/StandardAndroidBtn";
import CustomMuiInputNormal from "../../components/CustomMuiInput/CustomMuiInputNormal";
// IMGS & SVGS
import { arrowForward } from "ionicons/icons";
import NoDataImage from "../../components/NoDataImage/NoDataImage";
import InfoModal from "../../components/InfoModal/InfoModal";
import addTruck from '../../imgs/homePage/add-truck.svg'

function PayYourDriver() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const allTrucks = useSelector(s => s.truck.getAllTrucks);
    const { enqueueSnackbar } = useSnackbar();

    // MODAL
    const [noTrucksModal, setNoTrucksModal] = useState(false);

    const [payByValObj, setPayByValObj] = useState({
        driver_cost: { value: "", selected: false, fuel_cost: "" },
        driver_cost_fixed: { value: "", selected: false, fuel_cost: "" },
        driver_cost_per: { value: "", selected: false, fuel_cost: "" },
        driver_cost_after_fuel: { value: "", selected: false, fuel_cost: "" }
    });
    const [payByVal, setPayByVal] = useState('');
    const [payPerInput, setPayPerInput] = useState('');
    const [fuelCostPerKM, setFuelCostPerKM] = useState('');
    // Fetch Trucks Data
    const [truckId, setTruckId] = useState('');
    const [truckLoading, setTruckLoading] = useState(false);
    const [nextBtnLoading, setNextBtnLoading] = useState(false);

    useEffect(() => {
        if (location.pathname == '/pay-driver') {
            fetchAllTrucks();
            setPayByVal('');
            setPayPerInput('');
            setFuelCostPerKM('');
            setTruckId('');
            setPayByValObj({
                driver_cost: { value: "", selected: false, fuel_cost: "" },
                driver_cost_fixed: { value: "", selected: false, fuel_cost: "" },
                driver_cost_per: { value: "", selected: false, fuel_cost: "" },
                driver_cost_after_fuel: { value: "", selected: false, fuel_cost: "" }
            })
        }
    }, [location.pathname])

    const fetchAllTrucks = async () => {
        if (!await checkUserId()) return;

        setTruckLoading(true);
        const body = { user_id: await checkUserId() }

        await axios.post(`${process.env.REACT_APP_BASEURL}/truck/all-trucks`, body)
            .then(res => {
                if (res.data.success) {
                    dispatch({ type: FETCH_ALL_TRUCKS, payload: res.data.data })
                    setNoTrucksModal(res.data.data?.length <= 0)
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setTruckLoading(false);
            })
            .catch(err => {
                console.log(err);
                setTruckLoading(false);
            })
    }

    const onTruckSelected = (e) => {
        setTruckId(e.target.value);
        const selectedTruckData = allTrucks?.filter(a => a.truck_id == e.target.value)?.[0];

        // IF selectedTruckData?.pay_driver_type is Null
        if (!selectedTruckData?.pay_driver_type) {
            setPayByVal('driver_cost');
            setPayPerInput('');
            setFuelCostPerKM('');
            setPayByValObj({
                driver_cost: { value: "", selected: true, fuel_cost: "" },
                driver_cost_fixed: { value: "", selected: false, fuel_cost: "" },
                driver_cost_per: { value: "", selected: false, fuel_cost: "" },
                driver_cost_after_fuel: { value: "", selected: false, fuel_cost: "" }
            })
        } else {
            Object.keys(JSON.parse(selectedTruckData?.pay_driver_type))?.forEach(t => {
                if (JSON.parse(selectedTruckData?.pay_driver_type)?.[t]?.selected) {
                    setPayByVal(t);
                    setPayPerInput(JSON.parse(selectedTruckData?.pay_driver_type)?.[t]?.value);
                    setFuelCostPerKM(JSON.parse(selectedTruckData?.pay_driver_type)?.[t]?.fuel_cost)
                }
            })
            setPayByValObj(JSON.parse(selectedTruckData?.pay_driver_type))
        }
    }

    const onPayByChange = (e) => {
        setPayByVal(e.target.value);
        setPayPerInput(payByValObj?.[e.target.value]?.value);
        setFuelCostPerKM(payByValObj?.[e.target.value]?.fuel_cost);
    }

    const onNext = async () => {
        if (!await checkUserId()) return;
        if (!truckId) return enqueueSnackbar('Please Select a Truck', { variant: 'error' });
        if (!payByVal) return enqueueSnackbar('Please Select the Payment Type !!!', { variant: 'error' });
        if (!payPerInput) return enqueueSnackbar(`Please Add ${payBySelectLabel(payByVal)} !!!`, { variant: 'error' });
        if (!fuelCostPerKM) return enqueueSnackbar('Please Add Fuel Cost !!!', { variant: 'error' });

        setNextBtnLoading(true);

        let pay_driver_type = {}
        Object.keys(payByValObj)?.forEach(p => {
            let obj = {};
            if (payByValObj?.[p]?.value) {
                if (payByVal == p) {
                    obj = { value: parseFloat(payPerInput), selected: true, fuel_cost: parseFloat(fuelCostPerKM) }
                } else {
                    obj = { ...payByValObj?.[p], selected: false }
                }
            } else {
                obj = {
                    value: payByVal == p ? parseFloat(payPerInput) : '',
                    selected: payByVal == p ? true : false,
                    fuel_cost: payByVal == p ? parseFloat(fuelCostPerKM) : ''
                }
            }

            pay_driver_type = {
                ...pay_driver_type,
                [p]: obj
            }
        })

        let body = {
            user_id: await checkUserId(),
            truck_id: truckId,
            pay_driver_type
        }

        // console.log(body);
        const historyBody = { user_id: await checkUserId(), fuel_cost: parseFloat(fuelCostPerKM), truck_id: truckId, [payByVal]: parseFloat(payPerInput) }

        await axios.put(`${process.env.REACT_APP_BASEURL}/truck/update/pay-driver-type`, body)
            .then(res => {
                if (res.data.success) {
                    setNextBtnLoading(false);
                    history.push('/calculate-profit', { body: historyBody });
                } else {
                    setNextBtnLoading(false);
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
            })
            .catch(err => {
                console.log(err);
                setNextBtnLoading(false);
            })
    }

    const payBySelectLabel = (key) => {
        switch (key) {
            case 'driver_cost':
                return 'Per Miles ($)';

            case 'driver_cost_fixed':
                return 'Per Trip ($)'

            case 'driver_cost_per':
                return 'Percentage of Trip (%)'

            case 'driver_cost_after_fuel':
                return 'Percentage after Fuel (%)'

            default:
                return;
        }
    }

    const onNoTruckModalClose = () => {
        setNoTrucksModal(false);
        history.goBack();
    }

    console.log();
    return (
        <CustomIonicWrapper title='Payment Option' onBackClick={() => history.push('/home')} headerFontSize={25} >
            <div className="payDriver_cont">
                <section className="payDriver_section">
                    <div className="payDriver_data_cont" >
                        <div className="payDriver_data_cont_card1">
                            <FormControl fullWidth disabled={truckLoading} >
                                <InputLabel sx={{ color: '#000' }} >Select Truck</InputLabel>
                                <Select value={truckId} label="Select Truck" onChange={onTruckSelected} >
                                    {allTrucks?.map(t => <MenuItem key={t.truck_id} value={t.truck_id}>{t.truck_no}</MenuItem>)}
                                </Select>
                            </FormControl>


                            {!!payByVal && (
                                <>
                                    <h5 className="payDriver_driverPayHeading" >Driver Pay</h5>

                                    <FormControl fullWidth sx={{ marginTop: '20px' }} >
                                        <InputLabel sx={{ color: '#000' }} >Pay By</InputLabel>
                                        <Select value={payByVal} label="Pay By" onChange={onPayByChange} >
                                            {Object.keys(payByValObj)?.map(p => (
                                                <MenuItem value={p} key={p} >{payBySelectLabel(p)}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <CustomMuiInputNormal
                                        val={payPerInput} setVal={setPayPerInput} type='number' variant='outlined' mt={20}
                                        lable={payBySelectLabel(payByVal)}
                                        startAdornment={payByVal === 'driver_cost' || payByVal === 'driver_cost_fixed' ? '$' : '%'}
                                    />

                                    <div className="payDriver_driverPayDash" />

                                    <CustomMuiInputNormal
                                        val={fuelCostPerKM} setVal={setFuelCostPerKM} type='number' variant='outlined' mt={20}
                                        lable='Fuel Cost Per Gallon' startAdornment='$'
                                    />
                                </>
                            )}
                        </div>

                        <div className="payDriver_data_cont_links">
                            <h3>Check The Fuel Prices</h3>
                            <a href="https://www.eia.gov/petroleum/gasdiesel" target="_blank">
                                EIA Gas Prices
                            </a>
                            <a href="https://gasprices.aaa.com" target="_blank">
                                AAA Gas Prices
                            </a>
                        </div>
                    </div>

                    <div className='payDriver_btns_cont' >
                        {/* <StandardAndroidBtn label='Home' onClick={() => { }} /> */}

                        <StandardAndroidBtn label='Next' onClick={onNext} loading={nextBtnLoading} iconRight={nextBtnLoading ? null : arrowForward} />
                    </div>
                </section>

                <InfoModal open={noTrucksModal} onClose={onNoTruckModalClose}>
                    <div className='payDriver_noTrucksModal_cont' >
                        <NoDataImage img={addTruck} heading='Add Some Trucks To Calculate Profit' />
                    </div>
                </InfoModal>
            </div>
        </CustomIonicWrapper>
    )
}

export default PayYourDriver
