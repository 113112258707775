import './StandardTruckCards.css'
import { IonButton, IonIcon } from '@ionic/react'
import { Skeleton } from '@mui/material'
// Ion Icons
import { trashOutline, pencil } from 'ionicons/icons'
import addTruck from '../../imgs/homePage/add-truck.svg'
import addTruck2 from '../../imgs/truckInformation/addTruck2.jpg'

export const StandardTruckCards = ({ onClick, onDel, delLoading, onEdit, heading, noImg, className, children }) => {
    return (
        <div onClick={onClick} className='ion-activatable standard_truckCard_cont' >
            <div className='standard_truckCard_Btncont' >
                <IonButton fill='clear' mode='ios' className='standard_truckCard_edit' onClick={onEdit} >
                    <IonIcon icon={pencil} />
                    <span>Edit</span>
                </IonButton>
                <IonButton fill='clear' mode='ios' className='standard_truckCard_del' onClick={onDel} disabled={delLoading} >
                    {delLoading ? <ion-spinner name="crescent" /> : <IonIcon icon={trashOutline} />}
                </IonButton>
            </div>
            {!noImg && <img src={addTruck2} />}
            <div className={`standard_truckCard_Datacont ${className ? className : ''}`} >
                {heading && <h5>{heading}</h5>}
                {children}
            </div>
            <ion-ripple-effect />
        </div>
    )
}

export const StandardTruckCardsSkeleton = () => (
    <div className="standard_truckCard_cont standard_truckCard_cont_skeleton">
        <Skeleton animation="wave" height={120} variant='rectangular' sx={{ borderRadius: 1 }} />
        <div>
            <Skeleton animation="wave" height={25} width='65%' variant='rectangular' sx={{ borderRadius: 1, marginBottom: 2 }} />
            <Skeleton animation="wave" width='95%' sx={{ borderRadius: 1 }} />
            <Skeleton animation="wave" width='75%' sx={{ borderRadius: 1 }} />
            <Skeleton animation="wave" width='50%' sx={{ borderRadius: 1 }} />
        </div>
    </div>
)
