import './ForgotPassword.css'
import "../SignUp/Signup.css";
import { useState } from 'react';
import { IonImg } from '@ionic/react'
import { useHistory } from 'react-router';
import axios from 'axios';
import { useSnackbar } from 'notistack';
// Components
import CustomIonicWrapper from '../../components/CustomIonicWrapper/CustomIonicWrapper';
import StandardAndroidBtn from '../../components/StandardAndroidBtn/StandardAndroidBtn';
import CustomMuiInputNormal from '../../components/CustomMuiInput/CustomMuiInputNormal';
// IMGS & SVGS
import SignUplogo from '../../imgs/signUp-logo.png'

function ForgotPassword() {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const onSendToMail = async () => {
        if (!email) return enqueueSnackbar('No Email Found !!!', { variant: 'error' })
        setLoading(true);
        
        const body = { email }
        await axios.post(`${process.env.REACT_APP_BASEURL}/user/forgot-password`, body)
            .then(res => {
                if (res.data.success) {
                    enqueueSnackbar('Email Sent Successfully !!!', { variant: 'success' })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                return setLoading(false);
            })
            .catch(err => {
                console.log(err);
                return setLoading(false);
            })
    }

    return (
        <CustomIonicWrapper title='Forgot Password' onBackClick={() => history.push('/login')}>
            <IonImg src={SignUplogo} alt="My Truck Profit" className="signup_logo" />
            <div className="forgotpass_cont">
                <div className="forgotpass_data_cont">
                    <p>Enter your email to recive an email to reset your password</p>

                    <CustomMuiInputNormal lable='Email ID' val={email} setVal={setEmail} type='email' autofocus />

                    <StandardAndroidBtn label='Send' onClick={onSendToMail} loading={loading} />
                </div>
            </div>
        </CustomIonicWrapper>
    )
}

export default ForgotPassword